import { createSlice, Reducer, createAsyncThunk } from "@reduxjs/toolkit";
import api from '../api';

interface StateProps {
  chartData: any,
  needFetchTokens: string[],
  allTokensByChain: {
    [chainId: number]: {}
  }
}


const initialState: StateProps = {
  chartData:{},
  needFetchTokens: [],
  allTokensByChain:{}
};

export const token = createSlice({
  name: "token",
  initialState,
  reducers: {
    setNeedFetchTokens(state, {payload}){
      state.needFetchTokens = Array.from(new Set([...payload, state.needFetchTokens]))
    },
    setChainedTokens(state, {payload}){
      console.log(payload);
      const {chainId, tokens} = payload;
      state.allTokensByChain[chainId] = tokens
    }
  },
  extraReducers: (builder) => {
  }
});

export const { setNeedFetchTokens, setChainedTokens } = token.actions;

export default token.reducer as Reducer<typeof initialState>;
