import React, { lazy } from 'react';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import GitHub from '@mui/icons-material/GitHub';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import SvgIcon from '@mui/material/SvgIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands, solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import chatSvg from '../../assets/icon/chat.svg';
import docSvg from '../../assets/icon/doc.svg';
import githubSvg from '../../assets/icon/github.svg';


const More = (props:any) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const moreNavs = [
    {
      label: 'About',
      value: 'about',
    },
    {
      label: 'Docs',
      value: 'docs',
    },
    {
      label: 'Github',
      value: 'github',
    },
    {
      label: 'Discord',
      value: 'discord',
    }
  ]

  const getIcon = (item:any) => {
    switch(item.value){
      case 'about': 
        return (<ErrorOutline sx={{fontSize: 14}}/>)
      case 'docs': 
        return (<img src={docSvg} alt=''/>)
      case 'github': 
        return (<img src={githubSvg} alt=''/>)
      case 'discord': 
        return (<img src={chatSvg} alt=''/>)
      default: 
        return null
    }
  }
  return (
    <Grid {...props}>
      <MoreHoriz sx={{color: theme.palette.text.primary}} onClick={(e:any) => setAnchorEl(e.currentTarget)}/>
      <Popover 
          id="more"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '.MuiPaper-root':{
              width: 200,
              padding: `${theme.spacing(1.5)} 0`
            }
          }}
        >
          {
            moreNavs.map((item, index) => (
              <MenuItem key={index} sx={{padding: `${theme.spacing(1.5)} ${theme.spacing(2.25)}`}}>
                <span className="mr-0.5">{getIcon(item)}</span>
                {item.label}
              </MenuItem>
            ))
          }
        </Popover>
    </Grid>
  )
}

export default More