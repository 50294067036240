import {toHex} from "../sdk";

export * from './server';
export * from './fee';

// PERCENTAGE
export const DEFAULT_SETTING_SLIPPAGE = '10';

export const MIN_AUTO_SLIPPAGE_TOLERANCE = DEFAULT_SETTING_SLIPPAGE;
export const MAX_AUTO_SLIPPAGE_TOLERANCE = '25';

// MINUTES
export const DEFAULT_SETTING_DEADLINE = '20';

export const DEFAULT_TOKEN_URI = '/default-token.svg';

// used for warning states
export enum ALLOWED_PRICE_IMPACT {
  LOW = 0.01,
  MEDIUM = 0.03,
  HIGH = 0.05,
  BLOCKED = 0.15
}

export enum TipLevel {
  Info = "info",
  Warning = "warning",
  Error = "error"
}

export const DEFAULT_USER_PRECISION = 6;

export const DEFAULT_CHART_PRECISION = 8;

export const DEFAULT_PRECISION = 18;

export const MAX_PRECISION = 28;

export const REG_NUMBER = new RegExp(
  "^[1-9]+[0-9]*(\\.\\d{0," + DEFAULT_PRECISION + "})?$"
);

export const REG_DECIMAL_SIMPLE = /^\d*(\.\d*)?$/;

export const REG_DECIMAL = new RegExp(
  "^0(\\.\\d{0," + DEFAULT_PRECISION + "})?$"
);

export enum Swap_Direction {
  In = "In",
  Out = "Out"
}

export enum Maker_Mode {
  Average,
  Arithmetic,
  Geometric
}

export const DEFAULT_GRID_NUMBER = 10;

export const MAX_GRID_NUMBER = 20;

export const ETHER_ZERO = toHex(0);

export const DEFAULT_ACTION_TICK_NUMBER = 3;

export const DEFAULT_ACTION_TICK_RANGES = DEFAULT_ACTION_TICK_NUMBER * 2 + 1;

export const WEBSITE_INFO = process.env.REACT_APP_WEBSITE_INFO as string;

export const DEFAULT_PAGE_SIZE = 10;