import { createSlice, Reducer, createAsyncThunk } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import api from '../api';

interface StateProps {
  chartData: any,
  orderBook: {
    base: any[],
    quote: any[]
  }
}


const initialState: StateProps = {
  chartData:{},
  orderBook: {
    base: [],
    quote: []
  }
};

export const fetchPoolOrderbook = createAsyncThunk(
  'pool/fetch-pool-order-book',
  (params: any, ThunkAPI)=> {
    return api.fetchPoolOrderbook(params)
  }
)

export const pool = createSlice({
  name: "pool",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPoolOrderbook.fulfilled, (state, {payload}) => {
      const { data } = payload;
      if(!isEmpty(data)) {
        const refactorData = [...(data?.highs || []), data.current, ...(data?.lows || [])]?.reduce((all: {base: [], quote: []}, current: any) => {
          return {
            ...all,
            base: [...all.base, {origin_tick: current?.origin_tick, price: current.price, amount: current.amount_base}],
            quote: [...all.quote, {origin_tick: current?.origin_tick, price: current.price, amount: current.amount_quote}]
          };
        }, {base: [], quote: []})
        state.orderBook = {
          base: refactorData.base.filter((item: any) => Number(item.amount) > 0).reverse(),
          quote: refactorData.quote.reverse().filter((item:any) => Number(item.amount) > 0)
        }
      }
    })
  }
});

export default pool.reducer as Reducer<typeof initialState>;
