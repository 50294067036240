import {useEffect, useState, useMemo} from 'react';
import {useCurrentChainId} from './useCurrentChainId';
import { isEmpty } from 'lodash';
import {useAllTokensMap} from './useAllTokens';
import {useFetchTokenMetas} from './useTokenMeta';
import {setFetchedTokens} from '../reducer/fetchedToken';
import { useAppDispatch } from 'app/hooks';
import {useApi} from './useApi';

export function useTopTokens(){
  const [tokens, setTokens] = useState([]);
  const allTokens = useAllTokensMap();
  const { chainId } = useCurrentChainId();
  const dispatch = useAppDispatch()
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const allTokenAddress = useMemo(() => {
    if(!allTokens.size){
      return [];
    }
    return allTokens.map((item:any) => item.address).toArray();
  }, [allTokens])
  useEffect(() => {
    if(!api){
      return;
    }
    setLoading(true)
    api.fetchTopTokens().then(({data}:any) => {
      setTokens(data.tokens.map((item:any, index:number) => ({...item, id: index+1})));
      setLoading(false)
    }).catch((err:any) => {
      setLoading(false)
    })
  }, [api])
  const needFetchTokens = useMemo(() => {
    if(isEmpty(allTokenAddress) || isEmpty(tokens)) {
      return [];
    }
    return tokens.filter((item :any) => !allTokenAddress.includes(item?.address)).map((item:any) =>item.address)
  },[tokens, allTokenAddress])
  const tokenMeta:any = useFetchTokenMetas(needFetchTokens);
  useEffect(() => {
    if(isEmpty(tokenMeta)){
      return ;
    }
    dispatch(setFetchedTokens({chainId, tokenMeta}))
  }, [tokenMeta, chainId, dispatch])
  return {
    tokens: tokens.map((item: any) => ({
      ...item, 
      tvl_usd: Number(item.tvl_usd),
      volume_24h: Number(item.volume_24h),
      price_usd: Number(item.price_usd),
      price_change_24h: Number(item.price_change_24h),
      fees_24h: Number(item.fees_24h),
    })),
    loading
  }
}

export function useTokensByAddresses(addresses: string[]){
  const [tokens, setTokens] = useState([]);
  const allTokens = useAllTokensMap();
  const { chainId } = useCurrentChainId();
  const dispatch = useAppDispatch()
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const allTokenAddress = useMemo(() => {
    if(!allTokens.size){
      return [];
    }
    return allTokens.map((item:any) => item.address).toArray();
  }, [allTokens])
  useEffect(() => {
    if(isEmpty(addresses) || !api){
      return 
    }
    setLoading(true)
    api.fetchTokensByAddresses(addresses).then(({data}:any) => {
      setTokens(data.overviews.map((item:any, index:number) => ({...item, id: index+1})));
      setLoading(false)
    }).catch((err:any) => {
      setLoading(false)
    })
  }, [addresses, api])
  const needFetchTokens = useMemo(() => {
    if(isEmpty(allTokenAddress) || isEmpty(tokens)) {
      return [];
    }
    return tokens.filter((item :any) => !allTokenAddress.includes(item?.address)).map((item:any) =>item.address)
  },[tokens, allTokenAddress])
  const tokenMeta:any = useFetchTokenMetas(needFetchTokens);
  useEffect(() => {
    if(isEmpty(tokenMeta)){
      return ;
    }
    dispatch(setFetchedTokens({chainId, tokenMeta}))
  }, [tokenMeta, chainId, dispatch])
  return {
    tokens: tokens.map((item: any) => ({
      ...item, 
      tvl_usd: Number(item.tvl_usd),
      volume_24h: Number(item.volume_24h),
      price_usd: Number(item.price_usd),
      price_change_24h: Number(item.price_change_24h),
      fees_24h: Number(item.fees_24h),
    })),
    loading
  }
}