import {goerli, ropsten} from "./connectors/chains";
import {TokenInfo} from "../types";
import { SupportedChainId } from './chains'
import {chains} from "./connectors/wagmi";
import {mainnet} from "wagmi/chains";
import {WETH9} from "../sdk";
import {Token} from "@uniswap/sdk-core";
import {TEST_GOERLI, USDC_GOERLI, WETH_GOERLI, YETH_GOERLI, YUSDT_GOERLI} from "./tokens-instances";

const UNI_LIST = 'https://tokens.uniswap.org';
const CMC_ALL_LIST = 'https://api.coinmarketcap.com/data-api/v3/uniswap/all.json'
const COINGECKO_LIST = 'https://tokens.coingecko.com/uniswap/all.json';

export const DEFAULT_ALL_LIST_URLS: Array<string> = [
  UNI_LIST,
  // CMC_ALL_LIST,
  COINGECKO_LIST
]

export const DEFAULT_ACTIVE_LIST_URLS: Array<string> = [
  UNI_LIST
];

export const EMPTY_TOKEN = {
  "name": "Empty Token",
  "address": "",
  "symbol": "",
  "decimals": 6,
  "chainId": 10,
  "logoURI": '/default-token.svg'
}

type ChainTokenList = {
  readonly [chainId: string]: TokenInfo[]
}


export function isCelo(chainId: number): chainId is SupportedChainId.CELO | SupportedChainId.CELO_ALFAJORES {
  return chainId === SupportedChainId.CELO_ALFAJORES || chainId === SupportedChainId.CELO
}


export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: TokenInfo } = {
  ...(WETH9 as Record<number, TokenInfo>),
  [goerli.id]: WETH_GOERLI,
}

export const NATIVE_CURRENCY: { [chainId: number]: TokenInfo } = {
  [goerli.id]: {
    chainId: goerli.id,
    address: goerli.nativeCurrency!.symbol,
    ...goerli.nativeCurrency!,
    isToken: false,
    isNative: true,
    wrapped: WRAPPED_NATIVE_CURRENCY[goerli.id]
  },
}

export const COMMON_BASE_TOKENS: ChainTokenList = {
  [goerli.id]: [
    NATIVE_CURRENCY[goerli.id],
    USDC_GOERLI,
    WETH_GOERLI,
    TEST_GOERLI,
    YUSDT_GOERLI,
    YETH_GOERLI
  ],
}
