import React, { FC, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import BaseLayout from '../layout';
import Info from '../pages/overview';
import NotFound from '../pages/404/index';
import Pools from '../pages/pool';
import Pool from '../pages/pool/details';
import Tokens from '../pages/token';
import Token from '../pages/token/details';


const AppRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<BaseLayout />}>
        <Route path="/" element={<Info />}/>
        <Route path="overview" element={<Info />} />
        <Route path="overview/tokens/:token" element={<Token />} /> 
        <Route path="overview/pools/:pool" element={<Pool />} /> 
        <Route path="pools" element={<Pools />} />
        <Route path="pools/:pool" element={<Pool />} />
        <Route path="tokens" element={<Tokens />} />
        <Route path="tokens/:token" element={<Token />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
