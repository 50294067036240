import Decimal from 'decimal.js';


export function pricePrecision(
  value: string | number,
  isOriginal: boolean = false
): number {
  let str: number = 0;
  try {
    const price = new Decimal(value);
    // if (isOriginal) {
    //   return price.toFixed();
    // }
    if (price.isZero()) {
      return 0;
    }
    if (price.lt(0.000000001)) {
      str = 12;
    } else if (price.gte(0.000000001) && price.lte(0.0000001)) {
      str = 12;
    } else if (price.gte(0.0000001) && price.lte(0.00001)) {
      str = 10;
    } else if (price.gte(0.00001) && price.lte(0.001)) {
      str = 8;
    } else if (price.gte(0.001) && price.lte(1)) {
      str = 6;
    } else if (price.gte(1) && price.lte(100)) {
      str = 4;
    } else if (price.gte(100) && price.lte(1000000)) {
      str = 2;
    } else if (price.gte(1000000) && price.lte(100000000000)) {
      str = 0;
    } 
  } catch (e: any) {
    str = 0;
  }
  return str;
}