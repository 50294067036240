import React from 'react';
import { Arrow } from '../../components/svg-icon';
import {useTheme} from '@mui/material/styles';
import classNames from 'classnames';
import { percentValue } from 'utils';
import { Typography } from '@mui/material';

const TokenPrice = (props: any) => {
  const {value, delta, className, showDelta=true, type, ...restProps} = props;
  const theme = useTheme();
  return (
    <div className={classNames("text-base font-extrabold flex", className)} {...restProps}>
      {value} &nbsp;
      {showDelta ? (
        <span className="flex items-center">(&nbsp;
          <Typography variant="inherit" color={Number(delta) > 0 ? theme.palette.success.main : Number(delta) === 0 ? 'inherit' : theme.palette.error.main}><span className='font-extrabold'>{percentValue(delta)}</span></Typography>&nbsp;)
        </span>
      ) : null}
    </div>
  )
}

export default TokenPrice;