import {useEffect, useMemo, useState} from 'react';
import Gridex from '../api/gridex';
import { useCurrentChainId } from './useCurrentChainId';
import CHAINAPI from '../constants/api';


export function useApi(){
  const [api, setApi] = useState<any>(null);
  const { chainId } = useCurrentChainId();
  useEffect(() => {
    if(!chainId){
      return;
    }
    const switchApi = new Gridex({baseURL: CHAINAPI[chainId]})
    setApi(switchApi);
  },[chainId])
  return useMemo(() => {
    return api
  },[api])
}