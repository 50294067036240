import { createSlice, createSelector, Reducer } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface StateProps {
  theme: string;
}

const initialState: StateProps = {
  theme: localStorage.getItem("theme") || "dark",
};

export const personal = createSlice({
  name: "personal",
  initialState,
  reducers: {
    changeTheme(state, { payload = "dark" }) {
      state.theme = payload;
      localStorage.setItem("theme", payload);
    },
  },
});

export const { changeTheme } = personal.actions;

const selectBaseState = (state: RootState) => state.personal;
export const selectTheme = createSelector(
  selectBaseState,
  (rootState) => rootState.theme
);

export default personal.reducer as Reducer<typeof initialState>;
