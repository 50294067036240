import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer, {
  TableContainerProps,
} from "@mui/material/TableContainer";
import TableHead, { TableHeadProps } from "@mui/material/TableHead";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { isEmpty } from "lodash";
import { Stack } from "@mui/material";
import { alpha } from "@mui/material/styles";

export interface ColumnProps {
  id: string;
  label: any;
  minWidth?: number;
  width?: number;
  align?: "left" | "center" | "right";
  cellRender?: any;
}

export interface TableProps {
  rows: any[];
  columns: ColumnProps[];
  height?: number;
  className?: string;
  showHeader?: boolean | undefined;
  showMore?: boolean;
  loading?: boolean;
  pageSize?: number;
  nodataMessage?: string;
}

interface StyledHeaderProps extends TableHeadProps {
  show?: boolean | undefined;
}

const StyledHeader = styled(TableHead)<StyledHeaderProps>(
  ({ theme, show }) => ({
    background: theme.palette.background.paper,
    th: {
      background: alpha(theme.palette.background.paper, 1),
      "&:first-of-type": {
        paddingLeft: theme.spacing(2),
      },
      "&:last-child": {
        paddingRight: theme.spacing(2),
      },
    },
  })
);

const StyledTableRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  "&:hover": {
    filter: "opacity(60%)",
  },
  td: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(2),
    },
    "&:last-child": {
      paddingRight: theme.spacing(2),
    },
  },
}));

const StyledTableContiner = styled(TableContainer)<TableContainerProps>(
  ({ theme }) => ({
    background: alpha(theme.palette.background.paper, 0.4),
  })
);

export default function TableComponent(props: TableProps) {
  const {
    columns,
    rows,
    height,
    showHeader = true,
    pageSize = 5,
    showMore = false,
    loading = false,
    nodataMessage = "No Data",
    ...restProps
  } = props;
  const [showMoreState, setShowMoreState] = useState(false);
  return (
    <>
      <StyledTableContiner
        className="h-full mb-0.25"
        style={{ maxHeight: height ? height : "auto", overflowY: "scroll" }}
        {...restProps}
      >
        <Table stickyHeader aria-label="sticky table">
          <StyledHeader>
            <TableRow>
              {columns.map((column: ColumnProps) => (
                <TableCell
                  key={column.id}
                  align={column.align || "left"}
                  style={{
                    minWidth: column.minWidth,
                    width: column.width,
                    overflow: "hidden",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </StyledHeader>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Stack
                    className="h-16"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress color="primary" size={40} />
                  </Stack>
                </TableCell>
              </TableRow>
            ) : !isEmpty(rows) ? (
              (showMoreState ? rows : rows.slice(0, pageSize)).map(
                (row: any, index: number) => {
                  return (
                    <StyledTableRow hover tabIndex={-1} key={index}>
                      {columns.map((column: ColumnProps) => {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || "left"}
                            sx={{ width: column.width, overflow: "hidden" }}
                          >
                            {column.cellRender
                              ? column.cellRender(row)
                              : row[column.id]}
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                }
              )
            ) : (
              <StyledTableRow>
                <TableCell colSpan={columns.length}>
                  <Stack
                    className="h-16"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Typography color="text.secondary" align="left">
                      {nodataMessage}
                    </Typography>
                  </Stack>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContiner>
      {showMore && rows.length > pageSize ? (
        <Grid
          sx={{ textAlign: "left", width: "100%" }}
          style={{ display: showMoreState ? "none" : "block" }}
          className="ml-0.5"
        >
          <Button
            color="primary"
            variant="text"
            size="small"
            onClick={() => setShowMoreState(!showMoreState)}
          >
            {showMoreState ? "Up" : "View More"}
          </Button>
        </Grid>
      ) : null}
    </>
  );
}
