import React, { useState, memo } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from './paged-table';
import { getTxTitle, decimalNumber, shortName, formatDiffTime, shortString, getExplorerLink, ExplorerDataType } from '../utils';
import { GridColDef } from '@mui/x-data-grid';
import { useAllTokensMap } from '../hooks/useAllTokens';
import { useCurrentChainId } from 'hooks/useCurrentChainId';
import {useTheme} from '@mui/material/styles';
import cn from 'classnames';

const TransicationList = memo(function TransicationList(props:any){
  const {data, showHeader = true, height, loading} = props;
  const [activeType, setActiveType] = useState('all')
  const {chain} = useCurrentChainId();
  const theme = useTheme();
  const types = [
    { label: 'All', value: 'all' },
    { label: 'Swaps', value: 'swaps' },
    { label: 'Placements', value: 'placements' },
    { label: 'Settlements', value: 'settlements' },
  ];
  const allTokens = useAllTokensMap();
  const columns: GridColDef[] = [
    {
      field: 'type',
      width: 300,
      sortable: false,
      editable: false,
      renderHeader: () => {
        return (
          <div className="flex items-center">
            {types.map(item => (
              <Typography
                color={activeType === item.value ? 'text.primary' : 'text.secondary'}
                key={item.value}
                variant="body2"
                className={cn('pr-1 cursor-pointer', activeType === item.value ? 'font-bold' : '')}
                onClick={() => setActiveType(item.value)}
                sx={{
                  '&:hover':{
                    color: theme.palette.text.primary
                  }
                }}
              >{item.label}</Typography>
            ))}
          </div>
        )
      },
      align:'left',
      renderCell: ({row: record}) => {
        const baseTokenMeta = allTokens.get(record.base) || null
        const quoteTokenMeta = allTokens.get(record.quote) || null
        return (
          <Typography color="primary" variant="body2" component="a" href={getExplorerLink(chain, record.tx_hash, ExplorerDataType.TRANSACTION)}  target="_blank">
            {getTxTitle(record.type, {
                ...record, 
                pool:{
                  base:baseTokenMeta,
                  quote: quoteTokenMeta
                }
            })}
          </Typography>
        );
      }
    },
    {
      field: 'amount_usd',
      headerName: 'Total Value',
      width: 200,
      renderCell: ({row: record}) => {
        return <Typography variant="caption" color="text.primary" noWrap component='div'>${decimalNumber(record.amount_usd).replace('-', '')}</Typography>
      }
    },
    {
      field: 'base_amount',
      width: 150,
      headerName: 'Token Amount',
      renderCell: ({row: record}) => {
        const token0Meta = allTokens.get(record.base) || null
        return <Typography variant="caption" color="text.primary">{decimalNumber(record.base_amount).replace('-', '')}<Typography color='text.secondary' className="pl-0.5" variant='inherit' component='span'>{shortName(token0Meta?.symbol).toUpperCase()}</Typography></Typography>
      }
    },
    {
      field: 'quote_amount',
      width: 150,
      headerName: 'Token Amount',
      renderCell: ({row: record}) => {
        const token1Meta = allTokens.get(record.quote) || null
        return <Typography variant="caption" color="text.primary">{decimalNumber(record.quote_amount).replace('-', '')}<Typography color='text.secondary' className="pl-0.5" variant='inherit' component='span'>{shortName(token1Meta?.symbol).toUpperCase()}</Typography></Typography>
      }
    },
    {
      field: 'owner',
      headerName: 'Account',
      sortable: false,
      renderCell: ({row: record}) => {
          return <Typography color='primary' variant='caption' component="a" href={getExplorerLink(chain, record.owner, ExplorerDataType.ADDRESS)} target="_blank">{shortString(record.owner)}</Typography>
      }
    },
    {
      field: 'block_timestamp',
      headerName: 'Time',
      align:'right',
      headerAlign:'right',
      flex: 1,
      renderCell: ({row: record}) => {
          return formatDiffTime(record.block_timestamp);
      }
    },
  ]
  return (
    <Grid style={{height: height ? height : 'auto',}} className="mt-1">
      {showHeader ? <Typography className="text-xl pt-2">Transactions</Typography> : null}
      <Table 
        rows={data[activeType]?.map((item:any, index: number) => ({...item, id: index}))} 
        columns={columns} 
        height={height} 
        pageSize={10}
        rowCount={data[activeType].length}
        loading={loading}
      />
    </Grid>
  )
})

export default TransicationList;
