import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LatestBlock from '../components/latestBlock';
import { StyledPaper } from '../components/styled-components';
import Pools from '../components/pools';
import { useTopPools, usePoolsByAddresses } from 'hooks/useTopPools';
import { useAppSelector } from 'app/hooks';
import { selectWatchedPools } from 'reducer/watchlist';

const Index = () => {
  const {pools, loading} = useTopPools()
  const watchedPools = useAppSelector(selectWatchedPools)
  const {pools: watchedPoolsList, loading: wachedLoading} = usePoolsByAddresses(watchedPools)
  return (
    <Grid>
      <Grid className="flex justify-between items-center">
        <Typography>Your Watchlist</Typography>
        <LatestBlock />
      </Grid>
      <Grid className="mt-1.25">
        {watchedPoolsList.length ? (
          <Pools pools={watchedPoolsList} showLink={false} showTitle={false} loading={wachedLoading}/>
        ) : (
          <StyledPaper className='py-1.25 px-1.5 mt-1.25'>
            Saved pairs will appear here
          </StyledPaper>
        )}
      </Grid>
      <Grid className="mt-2.5">
        <Pools title="All Pairs" showLink={false} pools={pools} loading={loading}/>
      </Grid>
    </Grid>
  )
}

export default Index;