import { SUPPORTED_CHAINS} from "./chains";
import { configureChains, createClient } from "wagmi";
import memoize from "lodash/memoize";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { SafeConnector } from "@gnosis.pm/safe-apps-wagmi";

const getNodeRealUrl = (networkName: string) => {
  let host = null;
  switch (networkName) {
    case "homestead":
      if (process.env.REACT_APP_PUBLIC_NODE_REAL_API_ETH) {
        host = `eth-mainnet.nodereal.io/v1/${
          process.env.REACT_APP_PUBLIC_NODE_REAL_API_ETH
        }`;
      }
      break;
    case "rinkeby":
      if (process.env.REACT_APP_PUBLIC_NODE_REAL_API_RINKEBY) {
        host = `eth-rinkeby.nodereal.io/v1/${
          process.env.REACT_APP_PUBLIC_NODE_REAL_API_RINKEBY
        }`;
      }
      break;
    case "ropsten":
      if (process.env.REACT_APP_PUBLIC_NODE_REAL_API_ROPSTEN) {
        host = `ropsten.infura.io/v3/${
          process.env.REACT_APP_PUBLIC_NODE_REAL_API_ROPSTEN
        }`;
      }
      break;
    default:
      host = null;
  }

  if (!host) {
    return null;
  }

  const url = `https://${host}`;
  return {
    http: url,
    webSocket: url
      .replace(/^http/i, "wss")
      .replace(".nodereal.io/v1", ".nodereal.io/ws/v1"),
  };
};

export const { provider, chains } = configureChains(SUPPORTED_CHAINS, [
  jsonRpcProvider({
    rpc: (chain) => {
      if (chain.rpcUrls.nodeReal) {
        return (
          getNodeRealUrl(chain.network) || {
            http: chain.rpcUrls.nodeReal,
          }
        );
      }
      return { http: chain.rpcUrls.default };
    },
  }),
]);

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
});

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: "CloverSwap",
    appLogoUrl:
      "https://img.freepik.com/premium-vector/golden-coin-with-clover_149267-225.jpg",
  },
});

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: true,
  },
});

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
});

export const client = createClient({
  autoConnect: true,
  provider,
  connectors: [
    new SafeConnector({ chains }),
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    walletConnectConnector,
  ],
});

export const CHAIN_IDS = chains.map((c) => c.id);

export const isChainSupported = memoize((chainId: number) =>
  CHAIN_IDS.includes(chainId)
);
export const isChainTestnet = memoize(
  (chainId: number) => chains.find((c) => c.id === chainId)?.testnet
);
