import React, {useRef, useState, useEffect, useCallback} from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid, { GridProps } from "@mui/material/Grid";
import { useTheme, alpha } from "@mui/material/styles";
import Header from "./header";

interface RootGridProps extends GridProps {
  pathname?: string;
  mode?: string;
}

const RootGrid = styled(Grid)<RootGridProps>(() => {
  const theme = useTheme();
  return {
    width: "100vw",
    height: "100vh",
    background: theme.palette.background.default, 
    position: 'relative',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  };
});

const Content = styled("div")(({theme}) => ({
  height: "calc(100vh - 100px)",
  paddingTop: 87,
  width: 1200,
  paddingBottom: theme.spacing(5),
  color: theme.palette.text.primary,
  margin: `${theme.spacing(5)} auto`,
  boxSizing:'border-box',
  position: 'relative',
  '.bg':{
    position: 'absolute',
    width: '1141px',
    height: '600px',
    left: '-20%',
    top: -176,
    background: 'radial-gradient(50% 50% at 50% 50%, #0B51BB 0%, rgba(0, 0, 0, 0.88) 100%)',
    opacity: 0.6,
    filter: 'blur(100.5px)',
  }
}));

export default function BasicLayout() {
  const outlet = useOutlet();
  const [searchOn, onSearch] = useState(false);
  const theme = useTheme();
  const location = useLocation();
  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    const rootDom = document.querySelector('#app');
    rootDom?.scrollTo(0,0);
  },[location])
  
  useEffect(() => {
    const rootDom = document.querySelector('#app');
    rootDom?.addEventListener('scroll', e => {
      setScrollTop(rootDom?.scrollTop)
    })
  },[])
  
  return (
    <RootGrid id="app">
      <Header onSearch={onSearch} scrollTop={scrollTop}/>
      <div style={{filter:'blur(3px)', position:'fixed', zIndex: 99, width:'100vw', top: 60, height: 'calc(100vh - 60px)', display: searchOn ? 'block' : 'none', background: alpha(theme.palette.background.default, 0.5)}}></div>
      <Content id="container">
        <Grid className="bg"/>
        <Grid className="relative pb-3">
          {outlet}
        </Grid>
      </Content>
    </RootGrid>
  );
}
