import moment from "moment";
import React from "react";
import {
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Area,
  ComposedChart,
  Line
} from "recharts";
import {alpha , styled} from '@mui/material/styles';
import { decimalNumber } from "../../utils";
import { Typography } from "@mui/material";
import NoData from "components/NoData";
import { isEmpty } from "lodash";


const StyledTooltip = styled('div')(({theme}) => ({
  background: theme.palette.background.default,
  borderWidth: 0,
  border: `1px solid ${alpha(theme.palette.divider, 0.4)}`,
  boxShadow:'unset',
  padding: theme.spacing(2),
  outline: 0,
  borderRadius: theme.spacing(0.5)
}))

export default function AreaChart(props:any){
  const {data, ...restProps} = props;
  const filteredData = data?.filter((item:any) => !isNaN(item.x))
  return (
    !isEmpty(filteredData) ? (
      <ResponsiveContainer width='100%' height={props.height}>
        <ComposedChart
          data={filteredData.map((item:any) => ({...item, y: Number(item.y)}))}
          {...restProps}
        >
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="1" y2="0" height={props.height}>
              <stop offset="0.02%" stopColor='#06FFB4' stopOpacity={0.7}/>
              <stop offset="80%" stopColor='#3479FF' stopOpacity={0.7}/>
              <stop offset="100%" stopColor="#8E48FF" stopOpacity={0.5}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="x" tickFormatter={(value) => moment(value*1000).utc().format('DD')}/>
          <Tooltip  
            wrapperStyle={{borderImage:'none !important', background:'transparent', boxShadow:'unset'}}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <StyledTooltip>
                    <Typography variant='caption' component='div'>Time: {`${moment(label*1000).utc().format('MMM, DD, YYYY')}`}</Typography>
                    <Typography variant='caption' component='div'>Value: {`${decimalNumber(Number(payload[0].value))}`}</Typography>
                  </StyledTooltip>
                );
              }
              return null;
            }}
          />
          <Line type="linear" dataKey="y" stroke="url(#colorPv)" fill="url(#colorPv)" strokeWidth={3} dot={<></>}/>
          <Area type="linear" dataKey="y" stroke="url(#colorPv)" fill="url(#colorPv)"/>
        </ComposedChart>
      </ResponsiveContainer>
    ) : (
      <div style={{height: props.height}}>
        <NoData />
      </div>
    )
  );
}
