import Decimal from 'decimal.js';
import {isEmpty} from 'lodash';

export function decimalNumber(number:number | string  = 0, fixed: number | undefined = 4 ){
  if(Number(number) === 0){
      return '0.00';
  }
  const [int = '', float = ''] = number.toString().replace('$', '').split('.');
  if (int.length > 10) {
    const [dealInt, dealFloat] = new Decimal(int).div(new Decimal(10).pow(9)).toString().split('.');
    return `${dealInt}${dealFloat?.length ? `.${dealFloat.slice(0, fixed)}` : ''}B`;
  } else if (int.length > 7) {
    const [dealInt, dealFloat] = new Decimal(int).div(new Decimal(10).pow(6)).toString().split('.');
    return `${dealInt}${dealFloat?.length ? `.${dealFloat.slice(0, fixed)}` : ''}M`;
  } else if (int.length > 3) {
    const [dealInt, dealFloat] = new Decimal(int).div(new Decimal(10).pow(3)).toString().split('.');
    return `${dealInt}${dealFloat?.length ? `.${dealFloat.slice(0, fixed)}` : ''}K`;
  } else {
    if(Math.abs(Number(int)) > 0){
      return `${Number(int.toString().replace('-','')) > 0 ? int : 0}${float.length > 0 ? '.' : ''}${float.length > 0 ? float.slice(0, fixed) : '.00'}`;
    }else{
      return float.slice(0,4) === '0000' ? '<0.0001' : `${Number(int.toString().replace('-','')) > 0 ? int : 0}${float.length > 0 ? '.' : ''}${float.length > 0 ? float.slice(0, fixed) : '.00'}`;
    }
  }
};


export const formatedNumber = (number:string | number) => {
  const [int, float] = number.toString().split('.');
  const formatedInt = groupString(int.toString());
  const formatedFloat = float;
  return `${formatedInt}${formatedFloat.length ? `.${formatedFloat}` : ''}`;
}

export const groupString = (number:string) => {
  if(isEmpty(number)){
      return '';
  }
  const numberArr:Array<string> = [];
  const numberString = number.toString().split('').reverse().join('');
  for (let i = 0, l = numberString.length; i < l/3; i++) {
      const a = numberString.slice(3*i, 3*(i+1));
      numberArr.push(a);
  }
  const reverseArr = numberArr.map(item => item.toString().split('').reverse().join(''))
  return reverseArr.reverse().join(',')
}

export const percentValue = (value: string | number, abs: boolean | undefined = false) => {
  if(isNaN(Number(value))) {
    return  '--';
  }
  return abs ? `${new Decimal(value).times(100).toFixed(2)}%` :   `${Number(value) > 0 ? '+' : ''}${new Decimal(value).times(100).toFixed(2)}%`
}

export const reversePrice = (value: string | number) => {
  if(!value || isNaN(Number(value))) {
    return  '--';
  }
  return new Decimal(1).div(value).toFixed(6)
}

export const groupValue = (value: any[], length: number) => {
  if(isEmpty(value)){
    return [];
  }
  let newArr = [];
  for (let i = 0, l = value.length; i < l/length; i++) {
      const a = value.slice(length*i, length*(i+1));
      newArr.push(a);
  }
  return newArr;
}
