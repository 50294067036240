import "./App.css";
import React, { useMemo, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { createTheme } from "@mui/material/styles";
import getTheme from "./theme";
import AppRouter from "./route";
import ListsUpdater from "./reducer/lists/updater";
import {PaletteMode} from '@mui/material'


const SnackbarProviderComponent = (props: SnackbarProviderProps) => {
  return (
    <SnackbarProvider
      autoHideDuration={1000}
      preventDuplicate={true}
      hideIconVariant={true}
      dense={true}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};

function Updaters() {
  return (
    <>
      <ListsUpdater />
    </>
  );
}

function App() {
  const [themeMode] = useState<PaletteMode>("dark");
  const createThemeMode = useMemo(() => {
    const themeConfig = getTheme(themeMode);
    return themeConfig;
  }, [themeMode]);
  const modeTheme = createTheme(createThemeMode);

  return (
    <ThemeProvider theme={modeTheme}>
      <SnackbarProviderComponent>
        <Updaters />
        <AppRouter />
      </SnackbarProviderComponent>
    </ThemeProvider>
  );
}

export default App;
