
export interface IServerError {
  code: Server_Code;
  data?: null;
  details?: null;
  message: string;
}

export enum Server_Code {
  Not_Found = 404,
}

export enum Transaction_Code {
  Success= 0,
  FAil = 1
}
