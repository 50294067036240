import {getAddress} from "@ethersproject/address";

export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function shortString(str: string){
  if(!str){
    return ;
  }
  return `${str.slice(0,5)}...${str.slice(-4)}`
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  if(!address) {
    return  ''
  }
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export function shortenName(value: string | undefined, len: number = 6) {
  if (!value) {
    return '-';
  }
  if (value.length <= len) {
    return value.toUpperCase();
  } else {
    return value.substring(0, len).toUpperCase() + '...';
  }
}

export function shortenSymbol(token: any | null) {
  if (!token) {
    return '-';
  } else {
    return shortenName(token.symbol);
  }
}
