import { createSlice, createSelector, Reducer } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import {selectChainId} from './global/slice';
import {isAddress} from '../utils'

interface StateProps {
  pools: {
    [key:number | string]: string[]
  },
  tokens: {
    [key:number | string]: string[]
  }
}

const initialState: StateProps = {
  pools: {}, 
  tokens:{}
};

export const watchlist = createSlice({
  name: "watchlist",
  initialState,
  reducers: {
    setWatchPool(state, {payload}){
      const {chainId, data} = payload;
      if(state.pools[chainId]){
        state.pools = {
          ...state.pools,
          [chainId]: Array.from(new Set([...state.pools[chainId], data])).filter(item => isAddress(item))
        } 
      }else{
        state.pools = {
          ...state.pools,
          [chainId]: [data].filter(item => isAddress(item))
        }
      }
    },
    removeWatchPool(state, {payload}){
      const {chainId, data} = payload;
      state.pools = {
        ...state.pools,
        [chainId]: state.pools[chainId].filter(item => item !== data)
      }
    },
    setWatchToken(state, {payload}){
      const {chainId, data} = payload;
      if(state.tokens[chainId]){
        state.tokens = {
          ...state.tokens,
          [chainId]: Array.from(new Set([...state.tokens[chainId], data])).filter(item => isAddress(item))
        } 
      }else{
        state.tokens = {
          ...state.tokens,
          [chainId]: [data].filter(item => isAddress(item))
        }
      }
    },
    removeWatchToken(state, {payload}){
      const {chainId, data} = payload;
      state.tokens = {
        ...state.tokens,
        [chainId]: state.tokens[chainId].filter(item => item !== data)
      }
    }
  },
});

export const {setWatchPool, removeWatchPool, setWatchToken, removeWatchToken } = watchlist.actions;
const selectBaseState = (state: RootState) => state.watchlist;
export const selectWatchedPools = createSelector(selectBaseState, selectChainId, (state, chainId) => {
  if(!chainId || !Object.hasOwn(state.pools, chainId)){
    return []
  }else{
    return state.pools[chainId]
  }
})
export const selectWatchedTokens = createSelector(selectBaseState, selectChainId, (state, chainId) => {
  if(!chainId || !Object.hasOwn(state.tokens, chainId)){
    return []
  }else{
    return state.tokens[chainId]
  }
})

export const hasPoolWatched = (pool:string) => createSelector(selectWatchedPools, (pools) => {
  return pools.includes(pool);
})

export const hasTokenWatched = (token:string) => createSelector(selectWatchedTokens, (tokens) => {
  return tokens.includes(token);
})


export default watchlist.reducer as Reducer<typeof initialState>;
