import React from "react";
import {Stack, Typography, useTheme} from "@mui/material";
import {NoData as NoDataIcon} from './svg-icon';
import cn from 'classnames';


export default function NoData(props: any) {
  const {showImg = true} = props;
  const theme = useTheme();
  return (
    <Stack className={cn('h-full', "flex")} alignItems="center" justifyContent="center">
      {showImg ? <NoDataIcon color={theme.palette.divider}/> : null}
      <Typography color="text.secondary" align="center" component="div" className="pt-1.25">No Data</Typography>
    </Stack>
  );
}
