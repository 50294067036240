import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LatestBlock from '../components/latestBlock';
import { StyledPaper } from '../components/styled-components';
import Card from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import TokenPrice from '../components/token-price';
import TokenIcon from '../components/token-icon';
import Tokens from '../components/tokens';
import { useTokensByAddresses, useTopTokens } from '../../hooks/useTopTokens';
import { useAppSelector } from 'app/hooks';
import {selectWatchedTokens} from '../../reducer/watchlist';


const StyledTokenItem = (props: any) => {
  const {token, ...restProps} = props;
  return (
    <Card {...restProps}>
      <Grid className="flex items-center">
        <TokenIcon />
        <ListItemText 
          className='ml-0.5'
          primary="WBTC" 
          secondary={
            <TokenPrice value={token.value} delta={token.delta}/>
          }
        />
      </Grid>
    </Card>
  )
}

const Index = () => {
  const watchedTokens = useAppSelector(selectWatchedTokens);
  const {tokens: watchedTokensList, loading:watchedLoading} = useTokensByAddresses(watchedTokens)
  const {tokens: topTokens, loading} = useTopTokens()

  return (
    <Grid>
      <Grid className="flex justify-between items-center">
        <Typography>Your Watchlist</Typography>
        <LatestBlock />
      </Grid>
      <Grid className="mt-1.25">
        {watchedTokensList.length ? (
          <Tokens title="Watched Tokens" data={watchedTokensList} showLink={false} showTitle={false} loading={watchedLoading}/>
        ) : (
          <StyledPaper className='py-1.25 px-1.5 mt-1.25'>
            Saved tokens will appear here
          </StyledPaper>
        )}
      </Grid>
      <Grid className="mt-2.5">
        <Tokens title="All Tokens" data={topTokens} showLink={false} loading={loading}/>
      </Grid>
    </Grid>
  )
}

export default Index;