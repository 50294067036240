import {
  grey,
  yellow,
  pink,
  purple,
  blue,
  red,
  green,
} from "@mui/material/colors";
import { darken, ThemeOptions, alpha, Palette,  } from "@mui/material/styles";
import {PaletteMode} from '@mui/material'

interface ColorsProps {
  dividerColor: string;
  primaryColor: string;
  secondaryColor: string;
  textSecondary: string;
  paperBg: string;
  defaultBg: string;
  actionHover: string;
  textPrimary: string;
  tabSelect: string;
}

interface ThemeConfigProps {
  [key: string]: ColorsProps;
}

const themeConfig: ThemeConfigProps = {
  dark: {
    dividerColor: "#7476AB",
    primaryColor: "#528DFF",
    secondaryColor: "#78B8DD",
    paperBg: "#1F2031",
    defaultBg: "#000",
    actionHover: "#272B3D",
    textPrimary: "#FFF",
    textSecondary: "#8182A3",
    tabSelect: "#18191F",
  },
  light: {
    dividerColor: "#D7DCDD",
    primaryColor: "#528DFF",
    secondaryColor: "#78B8DD",
    paperBg: "#FFFFFF",
    defaultBg: "#F2F4F8",
    actionHover: grey[100],
    textPrimary: "#000",
    textSecondary: "#8182A3",
    tabSelect: "#18191F",
  },
};

const getTheme = (mode: PaletteMode): ThemeOptions => {
  const {
    dividerColor,
    primaryColor,
    textSecondary,
    paperBg,
    defaultBg,
    actionHover,
    textPrimary,
    secondaryColor,
    tabSelect,
  } = themeConfig[mode];
  const theme: ThemeOptions = {
    palette: {
      mode: mode,
      primary: {
        main: primaryColor,
        dark: darken(primaryColor, 0.3),
        contrastText: "#fff",
      },
      secondary: {
        main: secondaryColor,
        contrastText: "#fff",
      },
      background: {
        default: defaultBg,
        paper: paperBg,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
        disabled: "rgba(152,153,179,1)",
      },
      error: {
        main: "#FE385C",
      },
      success: {
        main: "#0CBA71",
      },
      warning: {
        main: "#E7B335",
      },
      info: {
        main: "#4499CE",
      },
      action: {
        disabled: "rgba(152,153,179,0.36)",
        disabledBackground: "rgba(43,46,69,0.5)",
        hover: actionHover,
        selectedOpacity: 0.5,
      },
      divider: dividerColor,
      grey: {
        900: "#2D2E43",
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: "Gilroy !important",
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            lineHeight:'inherit',
            '&.font-bold': {
              fontWeight: 700,
              fontFamliy: 'Gilroy Bold'
            }
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 24,
            height: 24,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '9px 29px',
            borderRadius: 36,
            lineHeight: 1,
            textTransform: 'capitalize',
          },
          contained:{
            background: 'linear-gradient(270deg, #227AFF 0%, #1890FF 100%)',
          },
          textSizeSmall:{
            padding: `0 !important`
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          filled: {
            height: 'auto',
            padding: `4px 8px !important`,
            background: alpha(textSecondary, 0.3)
          },
          label: {
            paddingLeft: 0,
            paddingRight: 0
          },
          avatar:{
            marginLeft: 0,
            marginRight: 0,
          }
        },
      },
      MuiInputBase: {
        styleOverrides: {
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: textPrimary,
            backgroundColor: actionHover,
            fontSize: "12px",
            padding: "8px",
          },
          arrow: {
            color: actionHover,
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(paperBg, 0.4),
            borderRadius: 8,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: 0,
            padding: `12px 8px`,
          },
          head: {
            //backgroundColor: alpha(paperBg, 0.4),
            color: textSecondary,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: 300,
            padding: "16px",
            boxSizing: "border-box",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            position: "relative",
            backgroundColor: defaultBg,
            borderRadius: 8,
            // borderWidth: 1,
            // borderStyle: 'solid',
            borderColor: mode === "light" ? "#24263D" : "#24263D",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            boxShadow: "none",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            // width: 1000
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 24,
          },
          scrollButtons: {
            width: "auto",
          },
          indicator:{
            width: `20px !important`,
            backgroundColor: textPrimary,
          }
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: "auto !important",
            maxWidth: "auto",
            padding: "12px 24px",
            textTransform: "capitalize",
            minHeight: "auto",
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "32px",
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          gutters: {
            paddingLeft: 8,
            paddingRight: 8,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            background: paperBg,
            margin: 0,
            minWidth: 360,
            width: 440,
          },
          paperWidthSm: {
            maxWidth: 750,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "16px 24px",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          fontSizeSmall: {
            fontSize: "1rem",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: '4px',
            background:paperBg,
            border: 0,
            borderRadius: 24,
            '&:hover':{
              '&::before':{
                border: 0,
              },
              '&::after':{
                border: 0,
              }
            }
          },
          input: {
            padding: '4px 8px', 
            '::placeholder': {
              color: textSecondary,
              opacity: 1,
              fontSize: 14
            }
          }
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            fontSize: "0.875rem",
            "&$selected": {
              color: primaryColor,
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            // padding: 16
            backgroundColor: darken(paperBg, 0.4),
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root:{
            margin: `0 8px`,
          },
          grouped: {
            textTransform: 'capitalize',
            color: textSecondary,
            margin: "4px",
            padding: `4px 16px`,
            border: 0,
            "&.Mui-selected": {
              color: textPrimary,
              backgroundColor: alpha('#fff', 0.1),
              "&:hover": {
                backgroundColor: alpha('#fff', 0.1),
              },
            },
            "&.Mui-disabled": {
              border: 0,
            },
            "&:not(:first-of-type)": {
              borderRadius: "16px",
            },
            "&:first-of-type": {
              borderRadius: "20px",
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
          },
        },
      },
    },
  };
  return theme;
};

export default getTheme;

export const colors = {
  networkEthereum: "#627EEA",
  networkOptimism: "#FF0420",
  networkOptimismSoft: "rgba(255, 4, 32, 0.16)",
  networkPolygon: "#A457FF",
  networkArbitrum: "#28A0F0",
  networkPolygonSoft: "rgba(164, 87, 255, 0.16)",
  networkEthereumSoft: "rgba(98, 126, 234, 0.16)",
};

export const colorsDark = {
  chain_1: colors.networkEthereum,
  chain_3: yellow[400],
  chain_4: pink[400],
  chain_5: green[400],
  chain_10: colors.networkOptimism,
  chain_137: colors.networkPolygon,
  chain_42: colors.networkArbitrum,
  chain_69: colors.networkEthereum,
  chain_42161: colors.networkEthereum,
  chain_421611: colors.networkEthereum,
  chain_80001: colors.networkPolygon,
  chain_137_background: purple[900],
  chain_10_background: red[900],
  chain_42161_background: blue[900],
};
