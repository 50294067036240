import { SupportedChainId } from '../constants/chains'
import {
  arbitrumRinkeby,
  optimismKovan,
  polygonMumbai,
  rinkeby,
  ropsten,
  mainnet,
  goerli,
  arbitrum,
  optimism,
  polygon,
  kovan,
} from "wagmi/chains";
import { Chain, etherscanBlockExplorers } from 'wagmi';

const ETHERSCAN_PREFIXES: { [chainId: number]: string } = {
  [mainnet.id]: etherscanBlockExplorers.mainnet.url,
  [ropsten.id]: etherscanBlockExplorers.ropsten.url,
  [rinkeby.id]: etherscanBlockExplorers.rinkeby.url,
  [arbitrumRinkeby.id]: etherscanBlockExplorers.arbitrumRinkeby.url,
  [goerli.id]: etherscanBlockExplorers.goerli.url,
  [kovan.id]: etherscanBlockExplorers.kovan.url,
  [optimism.id]: etherscanBlockExplorers.optimism.url,
  [optimismKovan.id]: etherscanBlockExplorers.optimismKovan.url,
  [polygonMumbai.id]: etherscanBlockExplorers.polygonMumbai.url,
  [polygon.id]: etherscanBlockExplorers.polygon.url,
}

export enum ExplorerDataType {
  ACCOUNT = 'account',
  TRANSACTION = 'transaction',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block',
}

/**
 * Return the explorer link for the given hash and hash type
 * @param chain the chain for which to return the hash
 * @param hash the hash to return a link for
 * @param type the type of the hash
 */
export function getExplorerLink(chain: Chain | undefined, hash: string | undefined, type: ExplorerDataType): string {
  if (!chain) return etherscanBlockExplorers.mainnet.url;
  if (chain.id === arbitrum.id) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://arbiscan.io/tx/${hash}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://arbiscan.io/address/${hash}`
      case ExplorerDataType.BLOCK:
        return `https://arbiscan.io/block/${hash}`
      default:
        return `https://arbiscan.io/`
    }
  }

  if (chain.id === rinkeby.id) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://rinkeby-explorer.arbitrum.io/tx/${hash}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://rinkeby-explorer.arbitrum.io/address/${hash}`
      case ExplorerDataType.BLOCK:
        return `https://rinkeby-explorer.arbitrum.io/block/${hash}`
      default:
        return `https://rinkeby-explorer.arbitrum.io/`
    }
  }

  const prefix = ETHERSCAN_PREFIXES[chain.id] ?? etherscanBlockExplorers.mainnet.url;

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${hash}`

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${hash}`

    case ExplorerDataType.BLOCK:
      if (chain.id === SupportedChainId.OPTIMISM || chain.id === SupportedChainId.OPTIMISTIC_KOVAN) {
        return `${prefix}/tx/${hash}`
      }
      return `${prefix}/block/${hash}`

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${hash}`
    default:
      return `${prefix}`
  }
}
