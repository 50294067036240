import React from "react";
// import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const StyledLoading = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // color: alpha(theme.palette.primary.main, 0.6)
  background: theme.palette.background.default,
}));

const Loading = (props: any) => {
  return (
    <StyledLoading>
      <svg
        version="1.1"
        id="loader-1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="80px"
        height="80px"
        viewBox="0 0 80 80"
      >
        <defs>
          <linearGradient id="circle">
            <stop offset="0%" stopColor="#A5FECB" />
            <stop offset="50%" stopColor="#20BDFF" />
            <stop offset="100%" stopColor="#5433FF" />
          </linearGradient>
          <linearGradient id="center" x1="100%" y1="0" x2="0" y2="0">
            <stop offset="0%" stopColor="rgba(15, 232, 235, 0)" />
            <stop offset="100%" stopColor="rgba(35, 123, 255, 1)" />
          </linearGradient>
          <linearGradient
            id="paint0_linear_5284_14952"
            x1="26.1394"
            y1="4.9375"
            x2="33.7078"
            y2="36.3224"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2BBFFF" />
            <stop offset="1" stopColor="#3068FA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5284_14952"
            x1="16.1204"
            y1="7.25393"
            x2="11.7678"
            y2="23.3552"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#27FFD8" />
            <stop offset="1" stopColor="#2F85FC" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5284_14952"
            x1="8.7846"
            y1="0.708985"
            x2="30.6505"
            y2="42.775"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#27FFD8" />
            <stop offset="0.365753" stopColor="#2BA1E9" />
            <stop offset="1" stopColor="#306CFB" />
          </linearGradient>
        </defs>
        <g id="logo" transform="translate(21.5,18)">
          <path
            id="logo_part1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.4665 16.5398C35.1817 16.0195 34.2248 15.8144 33.6405 15.6891L33.5852 15.6773L34.6141 16.4995C34.4954 16.4995 34.3444 16.4873 34.1656 16.4728C33.5109 16.4196 32.4826 16.3362 31.2933 16.7122C29.8937 17.1547 29.1916 18.9967 29.2092 19.8444L27.3455 20.0795C27.5096 20.134 28.0705 20.3126 28.6041 20.4468C30.4468 21.1027 31.5438 21.823 32.8137 23.2363C35.3329 26.0399 35.0679 29.7042 35.0341 29.4746C34.9076 29.1596 33.958 27.7186 33.0588 27.4364C33.4199 27.8703 34.4845 30.1097 34.4845 31.9845C34.4845 33.1763 34.4129 34.4634 33.6418 36.2698C33.6418 35.7662 32.6016 31.7798 29.6645 29.9424C27.3534 28.4966 23.6971 28.529 20.643 27.4364C18.5102 26.6734 17.8579 26.3513 15.4683 24.6004C15.4683 24.6004 16.0917 19.8064 19.1831 15.2134C17.8435 15.239 16.0916 16.8921 15.3832 17.7154C15.8479 15.3033 17.4554 13.6941 19.1831 11.994H17.5086C17.2182 11.994 18.5959 10.588 21.6462 9.54849C21.5917 9.40381 20.8359 6.88722 22.1147 4.9375C22.096 6.02024 24.2699 7.73773 25.7316 8.37244C27.1934 9.00715 29.1042 8.4671 30.592 9.01602C32.356 9.66683 33.5729 11.4674 33.9913 12.0864C34.0655 12.1961 34.1146 12.2688 34.1371 12.2912C34.1711 12.3251 34.2888 12.4261 34.4565 12.57C35.0288 13.0609 36.1833 14.0514 36.5801 14.5784C37.0932 15.2599 37.1003 16.2894 36.8972 17.0668C36.6941 17.8441 34.6969 18.6056 35.0817 18.3607C35.4666 18.1157 35.7126 16.9893 35.4665 16.5398ZM30.4701 11.7733C29.8059 11.4451 29.028 11.588 28.4668 11.6911C28.4141 11.7008 28.3633 11.7102 28.3147 11.7187C28.5493 11.7655 29.3002 12.3655 29.7788 12.7861C30.2573 13.2067 30.8563 13.1407 31.07 12.9279C31.253 12.7456 30.9495 12.0102 30.4701 11.7733Z"
            fill="url(#paint0_linear_5284_14952)"
            // fill="none"
          />
          <path
            id="logo_part2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0411 19.3961C14.6211 17.5436 13.9119 15.0843 14.2645 12.5083C14.6062 10.0123 15.8795 7.88429 17.6566 6.47023C13.9301 7.33397 10.9368 10.4397 10.3841 14.4769C9.8654 18.266 11.6398 21.8303 14.6377 23.7535C15.2049 21.1247 16.0254 19.4237 16.0254 19.4237C16.0254 19.4237 16.031 19.4141 16.0411 19.3961Z"
            fill="url(#paint1_linear_5284_14952)"
            // fill="none"
          />
          <path
            id="logo_part3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.0616275 16.8859C0.0699977 16.8308 0.0788141 16.7755 0.0880833 16.72C0.11901 16.535 0.144281 16.351 0.164139 16.1681C0.549438 16.64 0.908414 17.0249 1.22247 17.3045C3.70992 19.5188 4.13928 19.3026 4.13928 19.3026C4.13928 19.3026 3.59461 18.8556 2.66783 17.6683C2.63215 17.6226 2.59231 17.5721 2.54881 17.517L2.54847 17.5166C2.06469 16.9039 1.12752 15.7171 0.411855 14.0949C1.54541 8.41311 5.15514 3.50395 10.2083 0.708984C8.25946 4.23803 7.38038 8.47555 7.9628 12.8795C9.18781 22.1423 16.4684 29.0422 24.9709 29.637C27.0951 30.0617 29.5217 30.9315 30.7681 32.4055C31.0588 32.7492 31.3195 33.11 31.5502 33.4799C32.4477 34.8298 32.9915 36.575 32.7878 38.7437C32.5974 40.7709 31.9787 43.6454 31.1501 44.7368C31.3742 41.9631 31.1563 39.3445 29.8405 38.0136C28.7479 36.9085 27.3155 36.6613 25.9277 36.7705C24.8021 37.131 23.628 37.3956 22.4135 37.5534C13.7241 38.6823 5.60879 34.0039 1.99809 26.5313C2.42651 26.8944 2.81755 27.1856 3.15346 27.3924C5.99121 29.1394 6.37602 28.8518 6.37602 28.8518C6.37602 28.8518 5.76129 28.5065 4.6404 27.4988C4.59729 27.4601 4.54926 27.4174 4.49681 27.3708L4.49626 27.3703C3.55983 26.5379 1.21583 24.4543 0.306432 21.1987C0.269506 20.9772 0.236177 20.7543 0.206513 20.53C0.0438242 19.2998 -0.00159989 18.0814 0.0616275 16.8859Z"
            fill="url(#paint2_linear_5284_14952)"
            // fill="none"
          />
        </g>
        <circle
          className="process-circle"
          cx="40"
          cy="40"
          r="38"
          transform="rotate(0 40 40)"
          strokeWidth="4"
          stroke="url(#center)"
          fill="none"
          strokeLinecap="round"
          strokeDasharray="170,100"
          id="loading"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 40 40"
            to="360 40 40"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </StyledLoading>
  );
};

export default Loading;
