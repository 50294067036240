import {alpha} from '@mui/material/styles';

export default function getProps(themes :any){
  return {
    "paneProperties.background": themes.palette.background.paper,
    "paneProperties.backgroundGradientStartColor": themes.palette.background.paper,
    "paneProperties.backgroundGradientEndColor": themes.palette.background.paper,
    "scalesProperties.backgroundColor": themes.palette.background.paper,
    "mainSeriesProperties.style": 0,
    "volumePaneSize": 'small',
    "paneProperties.crossHairProperties.width": 0,
    'mainSeriesProperties.minTick': '100000000, 1, false',
    'mainSeriesProperties.candleStyle.upColor': themes.palette.success.main,
    'mainSeriesProperties.candleStyle.downColor':themes.palette.error.main,
    'mainSeriesProperties.candleStyle.wickUpColor':themes.palette.success.main,
    'mainSeriesProperties.candleStyle.wickDownColor': themes.palette.error.main,
    'mainSeriesProperties.candleStyle.borderUpColor': themes.palette.success.main,
    'mainSeriesProperties.candleStyle.borderDownColor': themes.palette.error.main,
  
    'mainSeriesProperties.hollowCandleStyle.upColor': themes.palette.success.main,
    'mainSeriesProperties.hollowCandleStyle.downColor':themes.palette.error.main,
    'mainSeriesProperties.hollowCandleStyle.wickUpColor':themes.palette.success.main,
    'mainSeriesProperties.hollowCandleStyle.wickDownColor': themes.palette.error.main,
    'mainSeriesProperties.hollowCandleStyle.borderUpColor': themes.palette.success.main,
    'mainSeriesProperties.hollowCandleStyle.borderDownColor': themes.palette.error.main,
  
    'mainSeriesProperties.haStyle.upColor': themes.palette.success.main,
    'mainSeriesProperties.haStyle.downColor':themes.palette.error.main,
    'mainSeriesProperties.haStyle.wickUpColor':themes.palette.success.main,
    'mainSeriesProperties.haStyle.wickDownColor': themes.palette.error.main,
    'mainSeriesProperties.haStyle.borderUpColor': themes.palette.success.main,
    'mainSeriesProperties.haStyle.borderDownColor': themes.palette.error.main,
  
    'mainSeriesProperties.barStyle.upColor': themes.palette.success.main,
    'mainSeriesProperties.barStyle.downColor':themes.palette.error.main,
    'mainSeriesProperties.barStyle.wickUpColor':themes.palette.success.main,
    'mainSeriesProperties.barStyle.wickDownColor': themes.palette.error.main,
    'mainSeriesProperties.barStyle.borderUpColor': themes.palette.success.main,
    'mainSeriesProperties.barStyle.borderDownColor': themes.palette.error.main,

    'mainSeriesProperties.baselineStyle.topFillColor1': alpha(themes.palette.success.main, 0.28),
    'mainSeriesProperties.baselineStyle.topFillColor2': alpha(themes.palette.success.main, 0.05),
    'mainSeriesProperties.baselineStyle.bottomFillColor1': alpha(themes.palette.error.main, 0.05),
    'mainSeriesProperties.baselineStyle.bottomFillColor2': alpha(themes.palette.error.main, 0.28),
    'mainSeriesProperties.baselineStyle.topLineColor': themes.palette.success.main,
    'mainSeriesProperties.baselineStyle.bottomLineColor': themes.palette.error.main,
  }
}