import * as React from 'react';
import './index.scss';
import {widget} from '../../charting_library';
import PropTypes from 'prop-types';
import getProps from './refactorProps';
import CHAINAPI from 'constants/api';
import Decimal from 'decimal.js';
import qs from 'qs';
var momentTZ = require('moment-timezone');

export default class TVChartContainer extends React.PureComponent {
  static defaultProps = {
    symbol: 'AAPL',
    interval: '15',
    containerId: 'tv_chart_container',
    libraryPath: '/charting_library/',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    enabled_features: ['two_character_bar_marks_labels'],
    timezome: 'Asia/Singapore',
  };
  tvWidget = null;

  componentDidUpdate(prevProps) {
    if (this.tvWidget && this.props.symbol && this.props.symbol !== prevProps.symbol) {
      this.rerenderTv();
    }
   /*  if (this.props.theme && this.props.themes && this.props.theme !== prevProps.theme) {
      //this.tvWidget.changeTheme(this.props.theme)
      this.tvWidget?.applyOverrides({
        ...getProps(this.props.themes),
      });
    } */
    if(this.props.themes && this.pricescale && this.props.pricescale !== prevProps.pricescale){
      this.tvWidget?.applyOverrides({
        ...getProps(this.props.themes),
        'mainSeriesProperties.minTick': `${new Decimal(10).pow(this.props.pricescale).toNumber()}, 1, false`,
      });
      this.tvWidget?.applyStudiesOverrides({
        'volume.precision': this.props.pricescale,
      });
    }
    if (this.props.chainId && this.props.chainId !== prevProps.chainId) {
      this.rerenderTv();
    }
  }

  rerenderTv() {
    const widgetOptions = {
      symbol: this.props.symbol,
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(`${CHAINAPI[this.props.chainId]}/v1/udf`, 10000),
      interval: this.props.interval,
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,
      locale: 'en',
      fullscreen: this.props.fullscreen,
      studies_overrides: {
        ...this.props.studiesOverrides,
      },
      height: 320,
      autosize: true,
      theme: this.props.theme === 'dark' ? 'Dark' : 'Light',
      timezome: this.props.timezome,
      disabled_features: [
        "left_toolbar",
        "border_around_the_chart",
        "header_symbol_search",
        "symbol_search_hot_key",
        "header_compare",
        "header_undo_redo",
        "go_to_date",
        "timeframes_toolbar",
        "header_saveload",
        "context_menus.legend_context_menu",
        "hide_last_na_study_output",
        //"header_settings",
        "header_screenshot",
        "create_volume_indicator_by_default",
        "use_localstorage_for_settings"
      ],
      // 使用项
      enabled_features: [
        "left_toolbar",
        'adaptive_logo',
        //"hide_left_toolbar_by_default"
      ],
      loading_screen:{
        backgroundColor:this.props.themes.palette.background.paper,
        foregroundColor: this.props.themes.palette.background.paper
      }
    };
    const tvWidget = new widget(widgetOptions);
    tvWidget.onChartReady(() => {
      tvWidget?.applyOverrides({
        ...getProps(this.props.themes),
        'mainSeriesProperties.minTick': `${new Decimal(10).pow(this.props.pricescale).toNumber()}, 1, false`,
      });
      tvWidget?.applyStudiesOverrides({
        'volume.precision': this.props.pricescale,
      });
      tvWidget.activeChart().dataReady(async () => {
        await tvWidget.activeChart().createStudy("Volume", false, false, {}, {'volume.precision': this.props.pricescale})
      })
      tvWidget.activeChart().setTimezone(momentTZ.tz.guess());
    })
    this.tvWidget = tvWidget;

  }

  componentDidMount() {
    const windowFetch = window.fetch;
		window.fetch = (inputs, options) => {
      if(typeof inputs === 'object'){
        const {url, body, method, ...restPorps} = inputs;
        return windowFetch(url, {...options, body, method})
      }else{
        const [path, requestParams] = inputs.split('?');
        if(path.includes('/history')){
          const originalParams = qs.parse(requestParams);
          const {to, countback } =  originalParams;
          return windowFetch(`${path}?${`${qs.stringify({
            ...originalParams, 
            to: Number(to)*1000 > Date.now() ? Math.floor(Date.now()/1000) : to,
            countback: countback > 5000 ? 5000 : countback
          })}`}`, options)
        }else{
          return windowFetch(`${path}${requestParams ? `?${requestParams}` : ''}`, options)
        }
      }
		}
    this.rerenderTv();
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return (
      <div
        id={this.props.containerId}
        className={'TVChartContainer'}
        style={{
          background: this.props.themes.palette.background.paper,
          '.chart-page':{
            backgroundColor: `${this.props.themes.palette.background.paper} !important`,
          }
        }}
      />
    );
  }
}


TVChartContainer.propTypes = {
  name: PropTypes.string,
  symbol: PropTypes.string,
  interval: PropTypes.string,
  containerId: PropTypes.string,
  datafeedUrl: PropTypes.string,
  libraryPath: PropTypes.string,
  fullscreen: PropTypes.bool,
  autosize: PropTypes.bool,
  studiesOverrides: PropTypes.object,
  enabled_features: PropTypes.array,
  timezome: PropTypes.string,
  theme: PropTypes.string,
  themes: PropTypes.object,
  chainId: PropTypes.number,
  pricescale: PropTypes.number
};
