import { usePool } from 'hooks/usePool';
import { useTopPools } from 'hooks/useTopPools';
import React from 'react';
import {GridColDef} from '@mui/x-data-grid'
import Table from '../../components/paged-table';
import { decimalNumber, toFormattedFee, percentValue } from 'utils';
import {Link} from 'react-router-dom';
import { useAllTokensMap } from 'hooks/useAllTokens';
import TokenIcon from './token-icon';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { StyledExpolor } from './styled-components';
import { useTheme } from '@mui/material';
import {Arrow} from '../../components/svg-icon';

const Pools = (props: any) => {
  const {showLink = true, pools = [], showTitle=true, to="/pools", loading} = props;
  const allTokens = useAllTokensMap();
  const theme = useTheme();
  const poolColumns: GridColDef[] = [
    {
        field: 'id',
        headerName: '#',
        sortable: false,
        width: 40,
        renderCell: (index:any) => {  
            return (
                <Link
                    to={`${to}/${index.row.address}`}
                    className="flex items-center"
                >
                  {index.api.getRowIndex(index.row.id) + 1}
                </Link>
            )
        },
    },
    {
        field: 'tick_spacing',
        headerName: 'Pair',
        flex: 1,
        renderCell: ({row: record}) => {
          const token0Meta = allTokens.get(record.base) || null;
          const token1Meta = allTokens.get(record.quote) || null;
          return (
              <Link
                  to={`${to}/${record.address}`}
                  className="flex items-center"
              >
                <TokenIcon src={token0Meta?.logoURI}/>
                <TokenIcon src={token1Meta?.logoURI} />
                <Grid className='text-base mx-0.75'>{token0Meta?.symbol}<Typography color='text.secondary' variant="inherit" component="span">/</Typography>{token1Meta?.symbol}</Grid>
                <Chip 
                  label={toFormattedFee(record.resolution)}
                />
              </Link>
            )
        },
    },
    {
      field: 'price_change_24h',
      headerName: 'Price Change',
      width: 180,
      align:'right',
      headerAlign:'right',
      renderCell: ({row: record}) => {
        return (
          <Typography variant='body2' color={record.price_change_24h > 0  ? theme.palette.success.main : record.price_change_24h < 0 ? 'error' : 'text.primary'} className="flex items-center font-bold">
            { percentValue(record.price_change_24h)}
          </Typography>
        )
      },
    },
    {
      field: 'fees_usd',
      headerName: 'Fees',
      width: 180,
      align:'right',
      headerAlign:'right',
      renderCell: ({row: record}) => {
        return `$${decimalNumber(record.fees_usd)}`
      }
    },
    {
        field: 'volume_usd_24h',
        headerName: 'Volume(24H)',
        headerAlign:'right',
        align: 'right',
        width: 180,
        renderCell: ({row: record}) => {
          return `$${decimalNumber(record.volume_usd_24h)}`
        }
      },
      {
        field: 'volume_usd_7d',
        headerName: 'Volume(7D)',
        width: 180,
        headerAlign:'right',
        align: 'right',
        renderCell: ({row: record}) => {
            return `$${decimalNumber(record.volume_usd_7d)}`
        }
    },
  ]
  return (
    <>
      {showTitle ? (
        <Grid className="flex justify-between">
          <Typography component="div">{props.title}</Typography>
          {showLink ? <StyledExpolor to="/pools"/> : null}
        </Grid>
      ) : null}
      <Table
        size={props?.size}
        rows={pools}
        pageSize={10}
        columns={poolColumns}
        className="mt-1.25"
        hideFooterPagination={pools.length <= 10}
        rowCount={pools.length}
        loading={loading}
      />
    </>
  )
}

export default Pools