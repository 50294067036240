import React, {memo} from 'react';
import Grid from '@mui/material/Grid';
import cn from 'classnames';
import { useTheme } from '@mui/material';
import nodataIcon from '../../assets/noData.svg';

const Arrow = (props: any) => {
  const { color='', rotate = 0, id = Date.now().toString() } = props;
  return (
    <Grid style={{transform: `rotate(${rotate}deg)`, display: 'inline', fill: color}} component="span">
      <svg width="14" height="14" viewBox="0 0 14 17" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.89821 0L0.624695 6.71712H4.6802V16.9194H9.11625V6.71712H13.1717L6.89821 0Z" fill={`url(#${Date.now()+id}`}/>
        <defs>
        <linearGradient id={Date.now()+id} x1="6.89821" y1="6.68004" x2="6.89821" y2="16.9194" gradientUnits="userSpaceOnUse">
        <stop stopColor={color}/>
        <stop offset="1" stopColor={color} stopOpacity="0"/>
        </linearGradient>
        </defs>
      </svg>
    </Grid>
  )
}

const Star = (props:any) => {
  const { color, className,  ...restProps } = props;
  return (
    <Grid className={cn(className, "flex items-center")} {...restProps}>
      <svg width="14" height="14" viewBox="0 0 18 18" fill="none">
        <path d="M8.322 15.0914L8.61257 15.7828L8.322 15.0914L4.35349 16.7592C4.18035 16.8319 3.99181 16.6949 4.00751 16.5078L4.36731 12.2181C4.40663 11.7494 4.2556 11.2846 3.94828 10.9285L1.13581 7.6696C1.01311 7.52742 1.08512 7.30578 1.26796 7.26287L5.45884 6.2795C5.91677 6.17204 6.31217 5.88477 6.55587 5.48246L8.78617 1.80057C8.88347 1.63994 9.11653 1.63994 9.21383 1.80057L11.4441 5.48246C11.6878 5.88477 12.0832 6.17204 12.5412 6.2795L16.732 7.26287C16.9149 7.30578 16.9869 7.52742 16.8642 7.6696L14.0517 10.9285C13.7444 11.2846 13.5934 11.7494 13.6327 12.2181L13.9925 16.5078C14.0082 16.6949 13.8196 16.8319 13.6465 16.7592L9.678 15.0914C9.24437 14.9092 8.75563 14.9092 8.322 15.0914Z" fill={color} stroke={color} strokeWidth="1.5"/>
      </svg>
    </Grid>
  )
}

const NoData = (props:any) => {
  const { color, className,  ...restProps } = props;
  const theme = useTheme()
  return (
    <Grid className={cn(className, "flex items-center")} {...restProps}>
      <img src={nodataIcon} alt='' width={80}/>
    </Grid>
  )
}

export { Arrow, Star, NoData };