import Decimal from "decimal.js";

export const getTxTitle = (type: string, record: any) => {
  switch (type) {
    case 'placements':
      return `Place ${shortName(record.pool?.base?.symbol)} and ${shortName(record.pool?.quote?.symbol)}`;
    case 'swaps':
      return new Decimal(record.base_amount).greaterThan(0) ?  `Swap ${shortName(record.pool?.base?.symbol)} for ${shortName(record.pool?.quote?.symbol)}` : `Swap ${shortName(record.pool?.quote?.symbol)} for ${shortName(record.pool?.base?.symbol)}`;
    case 'settlements':
      return `Settle ${shortName(record.pool?.base?.symbol)} and ${shortName(record.pool?.quote?.symbol)}`;
    default:
      return '';
  }
};

export const formatSwapTx = (
  amount0: string,
  amount1: string,
  symbol0: string,
  symbol1: string
) => {
  if (new Decimal(amount0).greaterThan(0)) {
    return `Swap ${shortName(symbol0)} for ${shortName(symbol1)}`;
  } else {
    return `Swap ${shortName(symbol1)} for ${shortName(symbol0)}`;
  }
};

export function shortName(value: string | undefined, len: number = 6) {
  if (!value) {
    return '-';
  }
  if (value.length <= len) {
    return value.toUpperCase();
  } else {
    return value.substring(0, len).toUpperCase() + '...';
  }
}

export function shortSymbol(token: any | null | undefined) {
  if (!token) {
    return '-';
  } else {
    return shortName(token.symbol);
  }
}
