import { Axios } from 'axios';
import defaults from 'axios/lib/defaults';
import qs from 'qs';

export default class Gridex extends Axios {
  constructor(config = {}){
    super({...defaults, ...config});
    this.interceptors.request.use(request => {
      request.baseURL = config.baseURL
      return request;
    });
    this.interceptors.response.use(async (response) => {
      return response.data;
    })
  }

  fetchOverview(query){
    return this.get('/v1/global/day_data?countback=100')
  }

  fetchPoolOverview(address){
    return this.get(`/v1/grids/info/overview/${address}`)
  }

  fetchPools(addresses){
    return this.post('/v1/grids/info/overviews', {addresses: addresses})
  }

  fetchTicker(query){
    return this.get(`/v1/grids/overview?${qs.stringify(query)}`)
  }

  fetchTopPools(){
    return this.get('/v1/grids/tops');
  }

  fetchWatehedPools(addresses){
    return this.post('/v1/grids/watch_list',{addresses});
  }

  fetchTopTokens(){
    return this.get('/v1/tokens/tops')
  }
  
  fetchTokensByAddresses(addresses){
    return this.post(`/v1/tokens/overviews`, {addresses: addresses})
  }

  fetchPoolByAddress(address){
    return this.get(`/v1/grids/info/overview/${address}`)
  }

  fetchPoolsByToken(address){
    return this.get(`/v1/tokens/grids/${address}`).then(resp => {
      const pools = resp.data.grids.map(item => item.address);
      return this.fetchPools(pools).then(({data}) => {
        const refactorPool = data.overviews.reduce((all ,item) => {
          return {
            ...all,
            [item.address]: item
          }
        }, {})
        return {
          ...resp,
          data: {
            ...resp.data,
            pools: resp.data.grids.map(item => ({...refactorPool[item.address], ...item}))
          }
        }
      })
    })
  }

  fetchWebsite24Value(){
    return this.get(`/v1/global/overview`)
  }
  
  fetchTokenOverview(address){
    return this.get(`/v1/tokens/overview/${address}`)
  }

  fetchLatestTransactions(){
    return this.get('/v1/global/transactions/latest?limit_per_type=100').then(resp => {
      const txData = resp.data;
      const pools = [...(txData?.swaps || []), ...(txData?.placements || []), ...(txData?.settlements || [])].map(item => item.address);
      return this.fetchPools(pools).then(({data}) => {
        const refactorPool = data.overviews.reduce((all ,item) => {
          return {
            ...all,
            [item.address]: item
          }
        }, {})
        const refactorTxData = Object.entries(txData).reduce((all, [type, datas]) => {
          return {
            ...all,
            [type]: datas.map(item => ({
              ...item, 
              ...refactorPool[item?.address]
            }))
          }
        },{swaps: [], placements: [], settlements: []})
        return {
          ...resp,
          data: refactorTxData
        };
      })
    })
  }
  fetchLatestTransactionsByAddress(type, address){
    return this.get(`/v1/${type}/transactions/latest/${address}?limit_per_type=100`).then(resp => {
      const txData = resp.data;
      const pools = Array.from(new Set([...(txData?.swaps || []), ...(txData?.placements || []), ...(txData?.settlements || [])].map(item => item.address)));
      return this.fetchPools(pools).then(({data}) => {
        const refactorPool = data.overviews.reduce((all ,item) => {
          return {
            ...all,
            [item.address]: item
          }
        }, {})
        const refactorTxData = Object.entries(txData).reduce((all, [type, datas]) => {
          return {
            ...all,
            [type]: datas.map(item => ({
              ...item, 
              ...refactorPool[item?.address]
            }))
          }
        },{swaps: [], placements: [], settlements: []})
        return {
          ...resp,
          data: refactorTxData
        };
      })
    })
  }

  fetchTokenChartData(query){
    const {address , countback = 100} = query
    return this.get(`/v1/tokens/day_data/${address}?countback=${countback}`)
  }

  fetchPoolChartData(query){
    const {address , countback = 100} = query
    return this.get(`/v1/grids/day_data/${address}?countback=${countback}`)
  }

  fetchPoolOrderbook(query){
    return this.get(`/v1/market/order_books?${qs.stringify(query)}`)
  }

  fetchSyncedBlockNumber(){
    return this.get(`/v1/global/latest_synced_block`);
  }

  searchPools(keyword){
    return this.get(`/v1/grids/by_token?keyword=${keyword}`);
  }
  searchTokens(keyword){
    return this.get(`/v1/tokens/by_token?keyword=${keyword}`);
  }
}