import moment from "moment";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import {alpha, useTheme, styled} from '@mui/material/styles';
import { decimalNumber } from "../../utils";
import { Typography } from "@mui/material";
import { isEmpty } from "lodash";
import NoData from '../NoData';

const StyledTooltip = styled('div')(({theme}) => ({
  background: theme.palette.background.default,
  borderWidth: 0,
  border: `1px solid ${alpha(theme.palette.divider, 0.4)}`,
  boxShadow:'unset',
  padding: theme.spacing(2),
  outline: 0,
  borderRadius: theme.spacing(0.5)
}))

export default function ColumnChart(props:any){
  const theme = useTheme();
  const {data,tickFormatter, ...restProps} = props
  return (
    !isEmpty(data) ? (
      <ResponsiveContainer width='100%' height={props.height}>
        <BarChart
          height={props.height}
          data={props.data.map((item:any) => ({...item, y: Number(item.y)}))}
          {...restProps}
        >
          <XAxis 
            dataKey="x" 
            tickFormatter={(value) => tickFormatter ? tickFormatter(value) : moment(value*1000).utc().format('DD')}
          />
          <Tooltip  
            wrapperStyle={{borderImage:'none !important', background:'transparent', boxShadow:'unset'}}
            cursor={<rect fill={alpha(theme.palette.background.paper, 0.4)} />}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <StyledTooltip>
                    <Typography variant='caption' component='div'>Time: {`${moment(label*1000).utc().format('MMM, DD, YYYY')}`}</Typography>
                    <Typography variant='caption' component='div'>Value: {`${decimalNumber(Number(payload[0].value))}`}</Typography>
                  </StyledTooltip>
                );
              }
              return null;
            }}
            />
          <Bar 
            yAxisId="left" 
            dataKey="y" 
            minPointSize={2} 
            fill="#3479FF"
            isAnimationActive={true}
            radius={[4, 4, 0, 0]}
            maxBarSize={40}
          />
        </BarChart>
      </ResponsiveContainer>
    ) : (
      <div style={{height: props.height}}>
        <NoData />
      </div>
    ) 
  );
}
