import React, { useEffect, useRef, useState, memo, useMemo } from "react";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import Popover, { PopoverProps } from "@mui/material/Popover";
import { useTheme, styled, alpha } from "@mui/material/styles";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Close from "@mui/icons-material/Close";
import { useAppSelector } from "../../app/hooks";
import { useTokensByAddresses, useTopTokens } from "../../hooks/useTopTokens";
import { usePoolsByAddresses, useTopPools } from "../../hooks/useTopPools";
import {
  selectWatchedPools,
  selectWatchedTokens,
} from "../../reducer/watchlist";
import { useSearch } from "../../hooks/useSearch";
import TokenIcon from "../../pages/components/token-icon";
import { decimalNumber, percentValue, toFormattedFee } from "../../utils";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useAllTokensMap } from "hooks/useAllTokens";
import Star from "../../pages/components/star";
import Chip from "@mui/material/Chip";
import { StyledText } from "pages/components/styled-components";
import searchIcon from "../../assets/search-icon.svg";
import Divider from "@mui/material/Divider";
import TableComponent, { ColumnProps } from "../../components/table";

const StyledInput = styled(OutlinedInput)<OutlinedInputProps>(({ theme }) => ({
  zIndex: 99999,
  minWidth: 260,
  border: 0,
  boxSizing: "border-box",
  padding: 0,
  paddingRight: theme.spacing(2),
  ".MuiOutlinedInput-input": {
    height: 35,
    padding: 0,
    background: "transparent",
    paddingLeft: theme.spacing(1.5),
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: `1px !important`,
    borderColor:
      theme.palette.mode === "dark"
        ? `transparent !important`
        : `${theme.palette.divider} !important`,
    "&:hover": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  "&.Mui-focused": {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
}));

const StylePopover = styled(Popover)<PopoverProps>(({ theme }) => ({
  ".MuiPaper-root": {
    zIndex: 9999,
    minWidth: 680,
    minHeight: 300,
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    background: alpha(theme.palette.background.paper, 1),
  },
}));

const StyledSearchComponent = styled("div")<any>(({ theme, open }) => ({
  zIndex: 9999,
  minWidth: 680,
  minHeight: 300,
  //padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  background: alpha(theme.palette.background.paper, 1),
  display: open ? "block" : "none",
  position: "absolute",
  top: 60,
  right: 50,
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(1),
}));

const StyledTabs = styled(Tabs)<TabsProps>(({ theme, value }) => {
  return {
    ".MuiTab-root": {
      width: 100,
    },
    ".MuiTabs-indicator": {
      left: `calc(40px + ${Number(value) * 100}px) !important`,
    },
    ".Mui-selected": {
      fontFamily: "Gilroy Bold !important",
    },
  };
});

const Search = memo(function Search(props: any) {
  const { onSearch } = props;
  const theme = useTheme();
  const [searchOpen, setSearchOpen] = useState(false);
  const allTokens = useAllTokensMap();
  const [activeTab, setActiveTab] = useState("0");
  const watchedPools = useAppSelector(selectWatchedPools);
  const { pools: watchedPoolsList, loading: watchedPoolsLoading } =
    usePoolsByAddresses(watchedPools);
  const watchedTokens = useAppSelector(selectWatchedTokens);
  const { tokens: watchedTokensList, loading: watchedTokensLoading } =
    useTokensByAddresses(watchedTokens);
  const { pools: topPools, loading: poolLoading } = useTopPools();
  const { tokens: topTokens, loading } = useTopTokens();
  const [keyword, setKeyword] = useState("");
  const { pools, tokens } = useSearch(keyword);
  const handleSearch = (e: any) => {
    setKeyword(e.target.value);
    setSearchOpen(true);
    onSearch(true);
  };

  const inputRef: any = useRef();
  const searchRef: any = useRef();
  const handleClose = () => {
    closeSearch();
    setKeyword("");
  };

  const closeSearch = () => {
    setSearchOpen(false);
    onSearch(false);
    setKeyword("");
  };

  const handleClick = (e: any) => {
    if (
      !(searchRef.current && searchRef.current.contains(e.target)) &&
      !(inputRef.current && inputRef.current.contains(e.target))
    ) {
      closeSearch();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  const showTokens = useMemo(() => {
    const filterTokens = keyword ? tokens : topTokens;
    return filterTokens.map((item) => ({
      ...item,
      watched: watchedTokens.includes(item.address),
    }));
  }, [keyword, topTokens, tokens, watchedTokens]);

  const showPools = useMemo(() => {
    const filterPools = keyword ? pools : topPools;
    return filterPools.map((item) => ({
      ...item,
      watched: watchedPools.includes(item.address),
    }));
  }, [keyword, topPools, pools, watchedPools]);

  const showWatchedToken = useMemo(() => {
    const filterTokens = keyword
      ? watchedTokensList.filter((item) => {
          const tokenMeta = allTokens.get(item.address) || null;
          return (
            tokenMeta?.name.toLowerCase().includes(keyword) ||
            tokenMeta?.symbol.toLowerCase().includes(keyword)
          );
        })
      : watchedTokensList;
    return filterTokens.map((item) => ({
      ...item,
      watched: watchedTokens.includes(item.address),
    }));
  }, [watchedTokensList, keyword, watchedTokens]);

  const showWatchedPools = useMemo(() => {
    const filterPools = keyword
      ? watchedPoolsList.filter((item) => {
          const token0Meta = allTokens.get(item.token0);
          const token1Meta = allTokens.get(item.token1);
          return (
            token0Meta?.name.toLowerCase().includes(keyword) ||
            token0Meta?.symbol.toLowerCase().includes(keyword) ||
            token1Meta?.name.toLowerCase().includes(keyword) ||
            token1Meta?.symbol.toLowerCase().includes(keyword)
          );
        })
      : watchedPoolsList;
    return filterPools.map((item) => ({
      ...item,
      watched: watchedPools.includes(item.address),
    }));
  }, [watchedPoolsList, keyword, watchedPools]);

  const columnToken: ColumnProps[] = [
    {
      id: "name",
      label: "Tokens",
      align: "left",
      cellRender: (record: any) => {
        const tokenMeta = allTokens.get(record.address) || null;
        return (
          <Typography className="flex items-center py-0.125" component="div">
            <Link
              to={`/tokens/${record.address}`}
              className="flex items-center"
              onClick={() => closeSearch()}
            >
              <TokenIcon src={tokenMeta?.logoURI} size={18} />
              <Grid className="text-sm ml-0.75">{tokenMeta?.name}</Grid>
              <StyledText
                className="text-xs pl-0.75"
                color={theme.palette.text.secondary}
              >
                ({tokenMeta?.symbol})
              </StyledText>
            </Link>
            <Star
              address={record.address}
              type="token"
              watched={record.watched}
              dumb={true}
            />
          </Typography>
        );
      },
    },
    {
      id: "price_usd",
      label: "Price",
      align: "right",
      width: 100,
      cellRender: (record: any) => {
        return Number(record.price_usd) > 0
          ? `$${decimalNumber(record.price_usd)}`
          : 0;
      },
    },
    {
      id: "price_change_24h",
      label: "Price Change",
      align: "right",
      width: 120,
      cellRender: (record: any) => {
        return (
          <Typography
            variant="body2"
            className="flex items-center justify-end"
            sx={{
              color:
                record.price_change_24h > 0
                  ? theme.palette.success.main
                  : record.price_change_24h < 0
                  ? theme.palette.error.main
                  : theme.palette.text.primary,
            }}
          >
            {percentValue(record.price_change_24h)}
          </Typography>
        );
      },
    },
    {
      id: "volume_usd_24h",
      label: "Volume(24H)",
      align: "right",
      width: 100,
      cellRender: (record: any) => {
        return `$${decimalNumber(record.volume_usd_24h)}`;
      },
    },
  ];
  const columnPool: ColumnProps[] = [
    {
      id: "tick_spacing",
      label: "Pairs",
      cellRender: (record: any) => {
        const token0Meta = allTokens.get(record.token0);
        const token1Meta = allTokens.get(record.token1);
        return (
          <Typography className="flex items-center" component="div">
            <Link
              to={`/pools/${record.address}`}
              className="flex items-center"
              onClick={() => closeSearch()}
            >
              <TokenIcon src={token0Meta?.logoURI} size={14} />
              <TokenIcon src={token1Meta?.logoURI} size={14} />
              <Grid className="text-small mx-0.5">
                {token0Meta?.symbol}
                <Typography
                  color="text.secondary"
                  variant="inherit"
                  component="span"
                >
                  /
                </Typography>
                {token1Meta?.symbol}
              </Grid>
              <Chip label={toFormattedFee(record.resolution)} />
            </Link>
            <Star
              address={record.address}
              type="pool"
              watched={record.watched}
              dumb={true}
            />
          </Typography>
        );
      },
    },

    {
      id: "price_usd",
      label: "Price",
      align: "right",
      width: 100,
      cellRender: (record: any) => {
        return `$${decimalNumber(record.price_usd)}`;
      },
    },
    {
      id: "price_change_24h",
      label: "Price Change",
      width: 120,
      align: "right",
      cellRender: (record: any) => {
        return (
          <Typography
            variant="body2"
            className="flex  items-center justify-end"
            sx={{
              color:
                record.price_change_24h > 0
                  ? theme.palette.success.main
                  : record.price_change_24h < 0
                  ? theme.palette.error.main
                  : theme.palette.text.primary,
            }}
          >
            {percentValue(record.price_change_24h)}
          </Typography>
        );
      },
    },
    {
      id: "volume_usd_24h",
      label: "Volume(24H)",
      align: "right",
      width: 100,
      cellRender: (record: any) => {
        return `$${decimalNumber(record.volume_usd_24h)}`;
      },
    },
  ];

  return (
    <Grid className="flex-1">
      <StyledInput
        placeholder="Search pairs or tokens"
        id="search-input"
        value={keyword}
        ref={inputRef}
        onChange={handleSearch}
        onFocus={(e: any) => {
          onSearch(true);
          setSearchOpen(true);
        }}
        autoComplete="off"
        fullWidth
        endAdornment={<img src={searchIcon} alt="" />}
      />

      <StyledSearchComponent
        open={searchOpen}
        id="search-component"
        ref={searchRef}
        className="pb-1"
      >
        <Grid className="flex justify-between items-center pr-1">
          <StyledTabs
            value={activeTab}
            textColor="inherit"
            onChange={(e, value) => setActiveTab(value)}
            className="relative"
          >
            <Tab label="search" value="0" />
            <Tab label="watchlist" value="1" />
          </StyledTabs>
          <Close
            fontSize="small"
            className="cursor-pointer"
            onClick={handleClose}
          />
        </Grid>
        {activeTab === "0" ? (
          <Grid>
            <TableComponent
              columns={columnToken}
              rows={showTokens}
              height={200}
              pageSize={3}
              showMore={true}
            />
            <div className="px-0.75 py-0.5">
              <Divider
                sx={{
                  borderStyle: "dashed",
                  filter: "opacity(30%)",
                  margin: `${theme.spacing(2)} ${theme.spacing(0)}`,
                }}
              />
            </div>
            <TableComponent
              columns={columnPool}
              rows={showPools}
              height={200}
              pageSize={3}
              showMore={true}
            />
          </Grid>
        ) : null}

        {activeTab === "1" ? (
          <Grid>
            <Grid className="mt-1">
              <TableComponent
                columns={columnToken}
                rows={showWatchedToken}
                height={200}
                pageSize={3}
                showMore={true}
                nodataMessage="Saved tokens will appear here"
              />
            </Grid>
            <div className="px-0.75 py-0.5">
              <Divider
                sx={{
                  borderStyle: "dashed",
                  filter: "opacity(30%)",
                  margin: `${theme.spacing(2)} ${theme.spacing(0)}`,
                }}
              />
            </div>
            <Grid>
              <TableComponent
                columns={columnPool}
                rows={showWatchedPools}
                height={200}
                pageSize={3}
                showMore={true}
                nodataMessage="Saved pools will appear here"
              />
            </Grid>
          </Grid>
        ) : null}
      </StyledSearchComponent>
    </Grid>
  );
});

export default Search;
