import { goerli }  from 'wagmi/chains';
import {createSlice, Reducer} from '@reduxjs/toolkit';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import {RootState} from '../../app/store';

interface StateProps {
  chain: any
}
const initialState: StateProps = {
  chain: goerli
}

export const global = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setChain(state, {payload}){
      state.chain = payload
    }
  }
})

export const {setChain} = global.actions
const selectBaseState = (state: RootState) => state.global;
export const selectChain = createSelector(selectBaseState, state => state.chain)
export const selectChainId = createSelector(selectChain, state => state.id)

export default global.reducer as Reducer<typeof initialState>;


