import { useEffect, useState, useMemo } from "react";
import {useApi} from './useApi';
import {isEmpty} from 'lodash';

export function useTokenInfo(address:string){
  const [tokenInfo, setState] = useState<any>({})
  const api = useApi();
  useEffect(() => {
    if(!address || !api){
      return ;
    }
    api.fetchTokenOverview(address).then(({data}:any) => {
      setState(data);
    })
  },[address, api])
  return useMemo(() => {
    return tokenInfo
  }, [tokenInfo])
}

export function useTokenChartData(address:string){
  const [data, setState] = useState<any>({})
  const api = useApi();
  useEffect(() => {
    if(!address || !api){
      return ;
    }
    api.fetchTokenChartData({address: address}).then(({data}:any) => {
      const {day_data} = data
      const refactorData = day_data.reduce((all: {tvl: [], volume: []}, current:any) => {
        return {
          ...all, 
          tvl: [
            ...all.tvl, 
            {
              ...current,
              x: current.date,
              y: current.tvl_usd
            }
          ],
          volume: [...all.volume, {
              ...current,
              x: current.date,
              y: current.volume_usd
            }
          ]
        }
      }, {tvl: [], volume: []})
      setState(refactorData);
    })
  },[address, api])
  return useMemo(() =>{ return data}, [data])
}