import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { styled, alpha } from "@mui/material";
import cn from "classnames";
import Logo from "./Logo";
import HeaderAccount from "./HeaderAccount";
import Search from './search';
import More from './more';
import ArrowForward from '@mui/icons-material/ArrowForward';

const StyledHeader = styled("header")<any>(({theme, scrollTop}) => ({
  background: scrollTop > 87 ? theme.palette.background.default : 'transparent',
  position: 'fixed',
  zIndex: 999,
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
}));

const NavLinkItem = styled(NavLink)<NavLinkProps>(() => {
  const theme = useTheme();
  return {
    position: "relative",
    margin: `0 ${theme.spacing(2)}`,
    color: alpha(theme.palette.text.primary, 0.8),
    fontWeight: 500,
    fontSize: '1rem',
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    "&.active": {
      color: `${theme.palette.text.primary} !important`,
      fontWeight: 700,
      fontFamily: "Gilroy Bold",
      opacity: 1,
      background: alpha(theme.palette.common.white, 0.1),
      borderRadius: theme.spacing(2)
    },
  };
});
const NavLinkA = styled('a')(() => {
  const theme = useTheme();
  return {
    position: "relative",
    margin: `0 ${theme.spacing(2)}`,
    color: alpha(theme.palette.text.primary, 0.8),
    fontWeight: 500,
    fontSize: '1rem',
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems:'flex-start',
    "&.active": {
      color: `${theme.palette.text.primary} !important`,
      fontWeight: 700,
      fontFamily: "Gilroy Bold",
      opacity: 1,
      background: alpha(theme.palette.common.white, 0.1),
      borderRadius: theme.spacing(2)
    },
  };
});

interface NavProps{
  label: string,
  value: string,
  href?: string,
  to: string
}

const navOptions: NavProps[] = [
  {
    value: "overview",
    label: 'Overview',
    to: "/overview",
  },
  {
    value: "pairs",
    label: 'Pairs',
    to: "/pools",
  },
  {
    value: "tokens",
    label: 'Tokens',
    to: "/tokens",
  },
  {
    value: "trade",
    label: 'To Trade',
    to: "/trade",
    href:'https://app.cloverswap.org/'
  },
];

const Header = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {onSearch, scrollTop} = props;
 
  return (
    <StyledHeader
      className={cn(
        "flex justify-between w-full px-2"
      )}
      scrollTop={scrollTop}
    >
      <Grid className="flex items-center flex-nowrap relative">
        <Logo color={theme.palette.text.primary}/>
        <Grid className="flex justify-around ml-11 items-center">
          {navOptions.map((nav: NavProps) => (
            nav.href ? (
              <NavLinkA href={nav.href} key={nav.value}>{t(`nav.${nav.value}`)}<ArrowForward color='inherit' fontSize="inherit" sx={{transform: 'rotate(-45deg)', marginLeft: '2px'}}/></NavLinkA>
            ) : (
              <NavLinkItem
                to={nav.to}
                className={({ isActive } : {isActive: boolean}) => (isActive ? "active" : "")}
                key={nav.value}
                replace={true}
              >
                {t(`nav.${nav.value}`)}
              </NavLinkItem>
            )
          ))}
        </Grid>
      </Grid>
      <Grid className="flex justify-end items-center relative ml-3 w-1/3">
        <HeaderAccount />
        <Search onSearch={onSearch}/>
        <More className="ml-1"/>
      </Grid>
    </StyledHeader>
  );
};

export default Header;
