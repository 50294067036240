import React from "react";

const Logo = (props: any) => {
  const {color} = props;
  return (
    <svg
      width="145"
      height="45"
      viewBox="0 0 145 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.262 24.0109H53.4419V27.4725H58.1409C57.5127 29.1291 55.9799 30.1428 53.6429 30.1428C50.2254 30.1428 48.1147 27.9917 48.1147 25.0494C48.1147 22.0082 50.326 19.8571 53.2911 19.8571C55.2763 19.8571 56.8594 20.7472 57.5881 21.8846L61.0055 19.956C59.523 17.6318 56.6835 15.9857 53.3162 15.9857C48.1398 15.9857 44.0941 20.0054 44.0941 25.0247C44.0941 29.9945 48.0644 34 53.5927 34C58.5178 34 62.262 30.7857 62.262 25.4945V24.0109Z"
        fill={color}
      />
      <path
        d="M75.6751 33.9948H80.2521L76.3222 27.2994C78.2571 26.3351 79.5638 24.4065 79.5638 22.2802C79.5638 18.6333 76.8751 15.9877 73.533 15.9877H66.497V33.9948H70.5176V27.967H72.1345L75.6751 33.9948ZM70.5176 19.6965H73.533C74.6386 19.6965 75.5432 20.5811 75.5432 22.2005C75.5432 23.4615 74.6386 24.5054 73.533 24.5054H70.5176V19.6965Z"
        fill={color}
      />
      <path
        d="M84.4488 15.9877V33.9948H88.4693V15.9877H84.4488Z"
        fill={color}
      />
      <path
        d="M101.019 15.9877H93.9835V33.9948H101.019C105.869 33.9948 109.563 29.8956 109.563 25C109.563 19.746 105.869 15.9877 101.019 15.9877ZM101.019 30.1871H98.0041V19.7954H101.019C103.784 19.7954 105.693 22.0329 105.693 25C105.693 27.967 103.784 30.1871 101.019 30.1871Z"
        fill={color}
      />
      <path
        d="M117.813 30.1871V26.7802H124.723V23.0219H117.813V19.7954H124.723V15.9877H113.792V33.9948H124.849V30.1871H117.813Z"
        fill={color}
      />
      <path
        d="M143.762 33.9948L138.236 24.572L143.178 15.9877H138.605L135.891 20.5619L133.177 15.9877H128.604L133.603 24.5631L128.051 33.9948H132.628L135.871 28.4436L139.158 33.9948H143.762Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2071 16.804C35.9223 16.2838 34.9654 16.0787 34.3811 15.9534L34.3258 15.9415L35.3547 16.7638C35.236 16.7638 35.085 16.7516 34.9062 16.737C34.2515 16.6839 33.2232 16.6005 32.0339 16.9765C30.6343 17.419 29.9322 19.261 29.9498 20.1087L28.0861 20.3438C28.2502 20.3983 28.8111 20.5769 29.3447 20.7111C31.1874 21.367 32.2844 22.0873 33.5543 23.5006C36.0735 26.3042 35.8085 29.9685 35.7747 29.7389C35.6482 29.4239 34.6986 27.9829 33.7994 27.7007C34.1605 28.1346 35.2251 30.374 35.2251 32.2488C35.2251 33.4406 35.1535 34.7277 34.3824 36.5341C34.3824 36.0305 33.3422 32.0441 30.4051 30.2067C28.094 28.7609 24.4377 28.7933 21.3836 27.7007C19.2508 26.9376 18.5985 26.6156 16.2089 24.8647C16.2089 24.8647 16.8323 20.0707 19.9237 15.4776C18.5841 15.5033 16.8322 17.1564 16.1238 17.9797C16.5885 15.5675 18.196 13.9584 19.9237 12.2583H18.2492C17.9588 12.2583 19.3365 10.8523 22.3868 9.81277C22.3323 9.66809 21.5765 7.1515 22.8553 5.20178C22.8366 6.28452 25.0105 8.00201 26.4722 8.63672C27.934 9.27143 29.8448 8.73138 31.3326 9.2803C33.0966 9.93111 34.3135 11.7317 34.7319 12.3507C34.8061 12.4604 34.8552 12.533 34.8777 12.5555C34.9117 12.5894 35.0294 12.6904 35.1971 12.8342C35.7694 13.3252 36.9239 14.3157 37.3207 14.8427C37.8338 15.5242 37.8409 16.5537 37.6378 17.331C37.4347 18.1084 35.4375 18.8699 35.8223 18.6249C36.2072 18.38 36.4532 17.2536 36.2071 16.804ZM31.2107 12.0376C30.5465 11.7093 29.7686 11.8523 29.2074 11.9554C29.1547 11.9651 29.1039 11.9744 29.0553 11.983C29.2899 12.0297 30.0408 12.6297 30.5194 13.0504C30.9979 13.471 31.5969 13.405 31.8106 13.1922C31.9936 13.0099 31.6901 12.2745 31.2107 12.0376Z"
        fill="url(#paint0_linear_360_459)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2071 16.804C35.9223 16.2838 34.9654 16.0787 34.3811 15.9534L34.3258 15.9415L35.3547 16.7638C35.236 16.7638 35.085 16.7516 34.9062 16.737C34.2515 16.6839 33.2232 16.6005 32.0339 16.9765C30.6343 17.419 29.9322 19.261 29.9498 20.1087L28.0861 20.3438C28.2502 20.3983 28.8111 20.5769 29.3447 20.7111C31.1874 21.367 32.2844 22.0873 33.5543 23.5006C36.0735 26.3042 35.8085 29.9685 35.7747 29.7389C35.6482 29.4239 34.6986 27.9829 33.7994 27.7007C34.1605 28.1346 35.2251 30.374 35.2251 32.2488C35.2251 33.4406 35.1535 34.7277 34.3824 36.5341C34.3824 36.0305 33.3422 32.0441 30.4051 30.2067C28.094 28.7609 24.4377 28.7933 21.3836 27.7007C19.2508 26.9376 18.5985 26.6156 16.2089 24.8647C16.2089 24.8647 16.8323 20.0707 19.9237 15.4776C18.5841 15.5033 16.8322 17.1564 16.1238 17.9797C16.5885 15.5675 18.196 13.9584 19.9237 12.2583H18.2492C17.9588 12.2583 19.3365 10.8523 22.3868 9.81277C22.3323 9.66809 21.5765 7.1515 22.8553 5.20178C22.8366 6.28452 25.0105 8.00201 26.4722 8.63672C27.934 9.27143 29.8448 8.73138 31.3326 9.2803C33.0966 9.93111 34.3135 11.7317 34.7319 12.3507C34.8061 12.4604 34.8552 12.533 34.8777 12.5555C34.9117 12.5894 35.0294 12.6904 35.1971 12.8342C35.7694 13.3252 36.9239 14.3157 37.3207 14.8427C37.8338 15.5242 37.8409 16.5537 37.6378 17.331C37.4347 18.1084 35.4375 18.8699 35.8223 18.6249C36.2072 18.38 36.4532 17.2536 36.2071 16.804ZM31.2107 12.0376C30.5465 11.7093 29.7686 11.8523 29.2074 11.9554C29.1547 11.9651 29.1039 11.9744 29.0553 11.983C29.2899 12.0297 30.0408 12.6297 30.5194 13.0504C30.9979 13.471 31.5969 13.405 31.8106 13.1922C31.9936 13.0099 31.6901 12.2745 31.2107 12.0376Z"
        fill="url(#paint1_radial_360_459)"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7818 19.6578C15.3616 17.8053 14.6524 15.3459 15.005 12.7698C15.3467 10.2739 16.6199 8.14597 18.3969 6.7319C14.6705 7.5957 11.6773 10.7014 11.1246 14.7385C10.6059 18.5277 12.3803 22.092 15.3783 24.0152C15.9455 21.3865 16.7659 19.6857 16.7659 19.6857C16.7659 19.6857 16.7715 19.676 16.7818 19.6578Z"
        fill="url(#paint2_linear_360_459)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.802092 17.1506C0.810489 17.0953 0.819334 17.0399 0.828636 16.9842C0.859548 16.7992 0.884811 16.6153 0.904665 16.4325C1.28997 16.9044 1.64896 17.2894 1.96302 17.5689C4.45047 19.7832 4.87983 19.5671 4.87983 19.5671C4.87983 19.5671 4.33517 19.12 3.40838 17.9327C3.3727 17.887 3.33287 17.8366 3.28936 17.7815L3.28902 17.781C2.80521 17.1683 1.86793 15.9813 1.15225 14.359C2.28566 8.67668 5.89568 3.76709 10.9492 0.972046C9.00013 4.50126 8.12088 8.73909 8.70334 13.1434C9.92834 22.4061 17.2089 29.3059 25.7113 29.9009C27.8355 30.3255 30.2622 31.1953 31.5086 32.6693C31.7968 33.0101 32.0554 33.3675 32.2846 33.734C33.1858 35.0852 33.7325 36.8334 33.5283 39.007C33.338 41.0342 32.7193 43.9086 31.8907 45.0001C32.1147 42.2264 31.8969 39.6078 30.581 38.2768C29.4885 37.1718 28.0561 36.9246 26.6684 37.0337C25.5427 37.3943 24.3686 37.6589 23.154 37.8167C14.4652 38.9455 6.35034 34.2678 2.73931 26.796C3.16746 27.1589 3.55828 27.4498 3.89401 27.6565C6.73176 29.4035 7.11657 29.1159 7.11657 29.1159C7.11657 29.1159 6.50184 28.7706 5.38095 27.763C5.33769 27.7241 5.28948 27.6812 5.23681 27.6344C4.30049 26.8021 1.95692 24.7189 1.0473 21.464C1.01024 21.2418 0.976803 21.0182 0.947053 20.7933C0.784428 19.5636 0.738975 18.3456 0.802092 17.1506Z"
        fill="url(#paint3_linear_360_459)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_360_459"
          x1="26.9429"
          y1="5.20178"
          x2="26.8829"
          y2="31.1584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2B9DEA" />
          <stop offset="1" stopColor="#2D59F5" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_360_459"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.7006 5.20178) rotate(67.5545) scale(17.923 12.3408)"
        >
          <stop stopColor="#28F9DA" />
          <stop offset="1" stopColor="#28EDFA" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_360_459"
          x1="16.8552"
          y1="7.49923"
          x2="12.5278"
          y2="23.5077"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27FFD8" />
          <stop offset="1" stopColor="#2D59F5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_360_459"
          x1="9.62399"
          y1="1.10145"
          x2="32.1144"
          y2="43.1025"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27FFD8" />
          <stop offset="0.365753" stopColor="#2BA1E9" />
          <stop offset="1" stopColor="#2D59F5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
