import { useEffect, useState, useMemo } from "react";
import { useCurrentChainId } from "./useCurrentChainId";
import {useBlockNumber as useChainBlockNumber} from 'wagmi';
import { useApi } from "./useApi";

export function useBlockNumber(){
  const [blockNumber, setBlockNumber] = useState<Number | null>(null);
  const realBlockNumber = useChainedBlockNumber();
  const api = useApi();
  useEffect(() => {
    if(!api){
      return ;
    }
    api.fetchSyncedBlockNumber().then(({data}: any) => {
      setBlockNumber(data.block_number)
    })
  }, [realBlockNumber, api])
  return blockNumber;
}

export function useChainedBlockNumber(){
  const { chainId } = useCurrentChainId()
  const { data: blockNumberData } = useChainBlockNumber({watch: true, chainId})
  return useMemo(() => {
    return blockNumberData
  },[blockNumberData])
}