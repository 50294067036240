import { useEffect, useMemo, useState } from "react";
import { useApi } from "./useApi";
import moment from 'moment';
import Decimal from 'decimal.js';
import {useChainedBlockNumber} from './useBlockNumber';

interface ChartDataProps {
  date?: number, 
  value?: number,
  x: number, 
  y: number | string,
  from? :number,
  to?: number
}

export function useGolbalOverview(){
  const [state, setState] = useState<any>();
  const api = useApi();
  useEffect(() => {
    if(!api){
      return
    }
    api.fetchOverview().then((resp:any) => {
      const {day_data} = resp.data
      const refactorData = day_data.reduce((all: {tvl: [], volume: []}, current:any) => {
        return {
          ...all, 
          tvl: [
            ...all.tvl, 
            {
              ...current,
              x: current.date,
              y: current.total_tvl_usd
            }
          ],
          volume: [...all.volume, {
              ...current,
              x: current.date,
              y: current.total_volume_usd
            }
          ]
        }
      }, {tvl: [], volume: []})
      const {tvl, volume} = refactorData;
      const weekData: ChartDataProps[][] = volume.reduce((all:any, current:any, index:number) => {
        const currentDay = moment(current.date*1000).utc().day()
        if(index === 0) {
          return [[current]]
        }else{
          const prevDay = moment(volume[index-1].date*1000).utc().day();
          return currentDay < prevDay ? [...all, [current]] : all.length > 1 ? [...all.slice(0, all.length-1),[...all.slice(-1)[0], current]] : [[...all[0], current]]
        }
      },  [] as ChartDataProps[][])
      const mothData:{[key:string] : ChartDataProps[]} = volume.reduce((all:any, current:any, index:number) => {
        const currentMonth = moment(current.date*1000).utc().month()
        return {
          ...all, 
          [currentMonth]: [...(all[currentMonth] || []), current]
        }
      },  {} as {[key:string]: ChartDataProps[]})
      const weekArray = weekData;
      const monthArray = Object.values(mothData);
      const volumeWeekData = weekArray.map((item:Array<ChartDataProps>) => {
          return {
              x: item[0]?.x,
              y: item.reduce((all,value) => all.plus(value.y), new Decimal(0)).toFixed(),
              from: item[0]?.date,
              to: item.slice(-1)[0]?.date
          }
      })
      const volumeMonthData = monthArray.map((item:Array<ChartDataProps>) => {
          return {
              x: item[0]?.x,
              y: item.reduce((all,value) => all.plus(value.y), new Decimal(0)).toFixed(),
              from: item[0]?.x,
              to: item.slice(-1)[0]?.x,
          }
      })
      setState({
        tvl: tvl,
        volume: {
          day: volume,
          week: volumeWeekData,
          month: volumeMonthData
        }
      })
    })
  },[api])
  return useMemo(() => {
    return state
  },[state])
}

export function useGolbalOverview24H(){
  const api = useApi();
  const blocckNumber = useChainedBlockNumber()
  const [state, setState] = useState<any>()
  useEffect(() => {
    if(!api){
      return ;
    }
    api.fetchWebsite24Value().then((resp:any) => {
      setState(resp.data)
    })
  }, [api, blocckNumber])
  return useMemo(() => {
    return state;
  }, [state])
}