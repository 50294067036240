import api from 'api';
import { useAppDispatch } from 'app/hooks';
import { isEmpty } from 'lodash';
import {useEffect, useState} from 'react';
import { useAllTokensMap } from './useAllTokens';
import { fetchTokensMeta } from 'reducer/fetchedToken';
import {useApi} from './useApi';

export function useSearch(keyword: string){
  const [pools, setPools] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [needFetchTokens, setNeedFetchTokens] = useState<string[]>([]);
  const allTokens = useAllTokensMap();
  const dispatch = useAppDispatch();
  const api = useApi()
  useEffect(() => {
    if(!keyword || !api){
      return 
    }
    api.searchTokens(keyword).then((resp:any) => {
      const tokens = resp.data.tokens.map((item:any, index: number) => ({...item, id: index+1}))
      const mapTokens = tokens.map((item:any) => !allTokens.has(item.address) ? item.address : '').filter((item: string) => item);
      setNeedFetchTokens(state => Array.from(new Set(state.concat(mapTokens))))
      setTokens(tokens)
    })
  },[keyword, api])

  useEffect(() => {
    if(!keyword || !api){
      return 
    }
    api.searchPools(keyword).then((resp:any) => {
      const pools = resp.data.grids?.map((item:any, index:number) => ({
        ...item, 
        id: index + 1,
      }))
      const mapTokens = pools.map((item:any) => [item.token0, item.token1]).flat().filter((item:string) => !allTokens.has(item));
      setNeedFetchTokens(state => Array.from(new Set(state.concat(mapTokens))))
      setPools(pools)
    })
  },[keyword, api])

  useEffect(() => {
    if(isEmpty(needFetchTokens)){
      return 
    }
    needFetchTokens.forEach(item => {
      dispatch(fetchTokensMeta(item))
    })
  },[needFetchTokens])
  return {
    pools: pools.map((item :any) => ({...item, tick_spacing: Number(item.tick_spacing), fees_usd: Number(item.fees_usd), volume_24h: Number(item.volume_24h), volume_usd_7d: Number(item.volume_usd_7d)})),
    tokens: tokens.map((item:any) => ({...item, price_usd: Number(item.price_usd), price_change_24h: Number(item.price_change_24h), volume_24h: Number(item.volume_24h)}))
  }
}