import { useContractReads } from 'wagmi';
import erc20ABI from '../abis/erc20.json';
import {useEffect, useMemo, useState} from 'react';
import { isEmpty } from 'lodash';
import {groupValue} from '../utils';
import { useCurrentChainId } from './useCurrentChainId';

export function useFetchTokenMetas(addresses: string[]){
  const { chainId } = useCurrentChainId();
  const [enabled, setEnabled] = useState(false);
  const contracts = useMemo(() => {
    if(isEmpty(addresses)){
      return [];
    }
    return addresses?.map(item => {
      return [
        {
          addressOrName: item,
          contractInterface: erc20ABI,
          functionName: 'name',
          chainId,
        },
        {
          addressOrName: item,
          contractInterface: erc20ABI,
          functionName: 'symbol',
          chainId,
        },
        {
          addressOrName: item,
          contractInterface: erc20ABI,
          functionName: 'decimals',
          chainId,
        },
      ]
    }).flat()
  },[addresses, chainId])
  const { data = [] } = useContractReads({
    contracts: contracts,
    enabled
  })
  useEffect(() => {
    if(isEmpty(contracts)){
      return ;
    }
    setEnabled(true)
  },[contracts])
  return useMemo(() => {
    if(isEmpty(data.filter(item => item)) || isEmpty(addresses)){
      return {}
    }
    const groupedValue = groupValue(data, 3);
    if(isEmpty(groupedValue)){
      return {};
    }
    return addresses.reduce((all, address, index) => {
      return {
        ...all, 
        [address]: {
          chainId,
          address,
          name: groupedValue[index][0] || 'Unknown Token',
          symbol: groupedValue[index][1] || "UNKNOWN",
          decimals: groupedValue[index][1]
        }
      }
    }, {})
  }, [data, addresses, chainId]);
}