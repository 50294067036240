import React from 'react';
import {GridColDef} from '@mui/x-data-grid'
import Table from '../../components/paged-table';
import { decimalNumber, percentValue } from 'utils';
import {Link} from 'react-router-dom';
import { useAllTokensMap } from 'hooks/useAllTokens';
import TokenIcon from './token-icon';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { StyledExpolor, StyledText } from './styled-components';
import { useTheme } from '@mui/material';
import { Arrow } from 'components/svg-icon';

const Tokens = (props: any) => {
  const { showLink = true, data:tokens = [], showTitle = true, title='', to="/tokens", loading} = props;
  const allTokens = useAllTokensMap();
  const theme = useTheme()
  const tokenColumns:GridColDef[] = [
    {
      field: 'id',
      headerName: '#',
      width: 20,
      sortable: false,
      renderCell: (index:any) => {  
          return (
              <Link
                  to={`${to}/${index.row.address}`}
                  className="flex items-center"
              >
                {index.api.getRowIndex(index.row.id) + 1}
              </Link>
          )
      },
  } ,
    {
        field: 'address',
        headerName: 'Name',
        flex: 1,
        renderCell: ({row: record}) => {
          const tokenMeta = allTokens.get(record.address) || null;
          return (
            <Typography
                component={Link}
                noWrap
                to={`${to}/${record.address}`}
                className="flex items-center primary-color"
            >
                <TokenIcon src={tokenMeta?.logoURI} size={24}/>
                <StyledText className="text-base ml-0.75 font-bold">{tokenMeta?.name}</StyledText>
                <StyledText color={theme.palette.text.secondary} className="text-sm pl-0.75">({tokenMeta?.symbol})</StyledText>
            </Typography>
          )
        }
    },
    {
        field: 'price_usd',
        headerName: 'Price',
        width: 180,
        align:'right',
        headerAlign:'right',
        renderCell: ({row: record}) => {
            return Number(record.price_usd) > 0 ? `$${decimalNumber(record.price_usd)}` : 0
        }
    },
    {
        field: 'price_change_24h',
        headerName: 'Price Change',
        align:'right',
        headerAlign:'right',
        width: 180,
        renderCell: ({row: record}) => {
            return (
                <Typography variant='body2' color={record.price_change_24h > 0  ? theme.palette.success.main : record.price_change_24h < 0 ? 'error' : 'text.primary'} className="flex items-center fontBold">
                  {percentValue(record.price_change_24h)}
                </Typography>
            )
        }
    },
    {
        field: 'volume_usd_24h',
        headerName: 'Volume(24H)',
        width: 180,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({row: record}) => {
            return `$${decimalNumber(record.volume_usd_24h)}`
        }
    },
  ]
  return (
    <>
      {showTitle ? (
        <Grid className="flex justify-between text-lg items-center">
          <Typography>{title ? title : 'Top Tokens'}</Typography>
          {showLink ? <StyledExpolor to="/tokens"/> : null}
        </Grid>
      ) : null}
      <Table
        size={props?.size}
        rows={tokens}
        pageSize={10}
        columns={tokenColumns}
        className="mt-1.25"
        hideFooterPagination={tokens.length <= 10}
        rowCount={tokens.length}
        loading={loading}
      />
    </>
  )
}

export default Tokens