import React, { useEffect, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { StyledPaper } from '../components/styled-components';
import { useParams, Link } from 'react-router-dom';
import TokenIcon from '../components/token-icon';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { Star } from '../../components/svg-icon';
import {useTheme} from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Launch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ColumnChart from '../../components/chart/column';
import TransicationList from '../../components/transication-list';
import { useTransactionByAddress } from '../../hooks/useTranscation';
import { usePool } from '../../hooks/usePool';
import { decimalNumber, toFormattedFee, getExplorerLink, ExplorerDataType, getFeeAmountByTickSpacing, percentValue, shortenAddress } from '../../utils';
import AreaChart from '../../components/chart/area';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useAllTokensMap } from '../../hooks/useAllTokens';
import { hasPoolWatched } from 'reducer/watchlist';
import { useCurrentChainId } from 'hooks/useCurrentChainId';
import {fetchTokensMeta} from '../../reducer/fetchedToken';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Paper, { PaperProps } from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {useTicker, usePoolChartData} from '../../hooks/usePool';
import TVChartContainer from 'components/tv-chart';
import StarComponent from 'pages/components/star';
import {pricePrecision} from 'utils';
import {DEFAULT_USER_PRECISION} from '../../constants';

const StyledTicker = styled(Paper)<PaperProps>(({theme}) => ({
  '.inner':{
    background: `linear-gradient(90.03deg, rgba(135,163,217, 0.05) 0.04%, #000 100%)`,
    position: 'relative',
    height: 54,
  }
}))

const Detail = () => {
  const {pool} = useParams() as {pool: string};
  const poolInfo = usePool(pool);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { chainId } = useCurrentChainId();
  const poolWatched = useAppSelector(hasPoolWatched(pool))
  const [chartTab, setChartTab] = useState('price')
  const {transaction, loading:txsLoading} = useTransactionByAddress('grids', pool);
  const dayData = usePoolChartData(pool);
  const allTokens = useAllTokensMap()
  const [activeData, setActiveData] = useState({x: '', y: ''})
  const baseTokenMeta = useMemo(() => {
    return allTokens.get(poolInfo.base) || null
  },[poolInfo.base, allTokens])
  const quoteTokenMeta = useMemo(() => {
    return allTokens.get(poolInfo.quote) || null
  },[poolInfo.quote, allTokens])
  const { chain } = useCurrentChainId()
  useEffect(() => {
    if(!isEmpty(baseTokenMeta) || !poolInfo.base){
      return
    }
    dispatch(fetchTokensMeta(poolInfo.base));
  }, [baseTokenMeta, poolInfo.base, dispatch])
  useEffect(() => {
    if(!isEmpty(quoteTokenMeta) || !poolInfo.quote){
      return
    }
    dispatch(fetchTokensMeta(poolInfo.quote));
  }, [quoteTokenMeta, poolInfo.quote, dispatch])

  const tickerData = useTicker({base: poolInfo.base, quote: poolInfo.quote, resolution: poolInfo.resolution})
  const tvTheme = useAppSelector(state =>  state.personal.theme);
  const chartValue = useMemo(() => {
    return chartTab === 'price' ? decimalNumber(tickerData?.price, 4) : activeData.x ? `$${decimalNumber(activeData.y)}` : dayData[chartTab]?.length ? `$${decimalNumber(dayData[chartTab].slice(-1)[0].y)}` : 0
  },[chartTab, dayData, activeData, tickerData?.price])
  const linePriceScale = useMemo(() => {
    return !isEmpty(tickerData) ? pricePrecision(tickerData?.price_usd): DEFAULT_USER_PRECISION; 
  },[tickerData])
  return (
    <Grid>
      <Breadcrumbs aria-label="breadcrumb" className="relative pb-2.5">
        <Typography variant="body1" component={Link} color="text.secondary" to='/overview'>
          Home
        </Typography>
        <Typography variant="body1" color="text.secondary" component={Link} to='/pools'>
          Pairs
        </Typography>
        <Typography variant="body1" color="text.primary">
          {baseTokenMeta?.symbol}/{quoteTokenMeta?.symbol} {toFormattedFee(poolInfo.resolution)}
        </Typography>
      </Breadcrumbs>
      <Grid>
        <Grid className="flex items-center">
          <TokenIcon src={baseTokenMeta?.logoURI} />
          <TokenIcon src={quoteTokenMeta?.logoURI} />
          <div className="font-bold text-xl ml-0.5">{baseTokenMeta?.symbol}/{quoteTokenMeta?.symbol}</div>
          <Chip 
            label={toFormattedFee(poolInfo.resolution)}
            variant="filled"
            className="ml-0.5"
          />
          <Grid className="flex items-center" component="a" href={getExplorerLink(chain, pool, ExplorerDataType.ADDRESS)}>
            <Typography color="primary" variant="caption" className="pl-0.5">{shortenAddress(pool)}</Typography>
            <ShareIcon className='ml-0.5' sx={{fontSize: 16}}/>
          </Grid>
          <StarComponent watched={poolWatched} type='pool' address={pool}/>
        </Grid>
        <StyledTicker className="mt-2 relative">
          <div className='inner'/>
          <div className="pl-1.25 py-0.75 absolute top-0 left-0 flex justify-between w-full h-full">
            <Grid className="flex items-center">
              <Box>
                <div className='font-bold text-sm'>{decimalNumber(tickerData?.price, 4)}</div>
                <Typography variant="caption" color="text.secondary"  className='leading-normal'>${decimalNumber(tickerData?.price_usd, 4)}</Typography>
              </Box>
              <Box className="ml-1.25">
                <Typography color={Number(tickerData?.price_change_24h) === 0  ? 'text.primary' : Number(tickerData?.price_change_24h) > 0 ? 'success.main' : 'error.main'} variant="caption" component="div" className='leading-normal'>{percentValue(tickerData?.price_change_24h, false)}</Typography>
                <Typography variant="caption" color="text.secondary">Change 24h</Typography>
              </Box>
              <Box className="ml-1.25">
                <div className="text-xs">{decimalNumber(tickerData?.low_24h, 4)}</div>
                <Typography variant="caption" color="text.secondary">Low 24h</Typography>
              </Box>
              <Box className="ml-1.25">
                <div className="text-xs">{decimalNumber(tickerData?.high_24h, 4)}</div>
                <Typography variant="caption" color="text.secondary">High 24h</Typography>
              </Box>
              <Box className="ml-1.25">
                <div className="text-xs">{decimalNumber(tickerData?.base_volume_24h, 4)}</div>
                <Typography variant="caption" color="text.secondary">Volume 24h ({baseTokenMeta?.symbol})</Typography>
              </Box>
              <Box className="ml-1.25">
                <div className="text-xs">{decimalNumber(tickerData?.quote_volume_24h, 4)}</div>
                <Typography variant="caption" color="text.secondary">Turnover 24h ({quoteTokenMeta?.symbol})</Typography>
              </Box>
              <Box className="ml-1.25">
                <div className="text-xs">${decimalNumber(tickerData?.fees_usd_24h, 4)}</div>
                <Typography variant="caption" color="text.secondary">24 Fees</Typography>
              </Box>
            </Grid>
            <Grid className="flex justify-between items-center relative">
              <Paper className="flex p-0.5 items-center rounded-full relative" sx={{borderRadius: theme.spacing(2.5)}}>
                <Typography component={Link} to={`/tokens/${poolInfo.base}`} noWrap className='flex items-center'>
                  <TokenIcon src={baseTokenMeta?.logoURI} />
                  <div className="pl-0.5 text-sm font-bold">{baseTokenMeta?.symbol}</div>
                </Typography>
                <Typography variant="body2" color="primary" component="a" href={getExplorerLink(chain, poolInfo.base, ExplorerDataType.TOKEN)} className='pl-0.5'>{shortenAddress(baseTokenMeta?.address)}</Typography>
              </Paper>
              <Paper className="flex p-0.5 items-center rounded-full ml-0.5" sx={{borderRadius: theme.spacing(2.5)}}>
                <Typography component={Link} to={`/tokens/${poolInfo.quote}`} noWrap className='flex items-center'>
                  <TokenIcon src={quoteTokenMeta?.logoURI} />
                  <div className="pl-0.5 text-sm font-bold">{quoteTokenMeta?.symbol}</div>
                </Typography>
                <Typography variant="body2" color="primary" component="a" href={getExplorerLink(chain, poolInfo.quote, ExplorerDataType.TOKEN)}  className='pl-0.5'>{shortenAddress(quoteTokenMeta?.address)}</Typography>
              </Paper>
              <Button variant="contained" color="primary" sx={{marginLeft: theme.spacing(1)}} component="a" href={`https://app.cloverswap.org/trade/${poolInfo.base}/${poolInfo.quote}/${getFeeAmountByTickSpacing(poolInfo.resolution)}`}>Trade</Button>
            </Grid>
          </div>
        </StyledTicker>
      </Grid>
      <Grid className="flex mt-1.25">
        <StyledPaper className="h-full flex-1 ">
          <Grid className='flex justify-between items-start px-2.25 py-1.25'>
            <Grid className='h-[60px]'>
                <Typography variant='h5' noWrap>{ chartValue }</Typography>
                <Typography variant='caption' component="div" color='text.secondary'>{activeData.x ?  `${activeData.x}(UTC)` : ''}</Typography>
            </Grid>
            <ToggleButtonGroup
              value={chartTab}
              exclusive
              onChange={(e, value) => { setChartTab(value) }}
            >
              <ToggleButton value="price">Price</ToggleButton>
              <ToggleButton value="volume">Volume</ToggleButton>
              {/* <ToggleButton value="tvl">TVL</ToggleButton> */}
              <ToggleButton value="fees">Fees</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid style={{height: 325}}>
            {chartTab === 'price' ? (
                <TVChartContainer 
                  symbol={`GRID-${pool}`}
                  theme={tvTheme}
                  themes={theme}
                  chainId={chainId}
                  pricescale={linePriceScale}
                />
            ) : null}
            {chartTab === 'fees' ? (
              <AreaChart 
                data={dayData[chartTab]}
                height={325}
                onMouseLeave={() => {
                  setActiveData({x: '', y: ''})
                }}
                onMouseMove={(state:any) => {
                  console.log(state?.activePayload[0]?.value);
                  setActiveData({x: state?.activeLabel ? moment(state?.activeLabel*1000).utc().format('MMM, DD, YYYY') : '', y: isEmpty(state?.activePayload) ? '' : state?.activePayload[0]?.value})
                }}
              />
            ) : null}
            {chartTab === 'volume' ? (
              <ColumnChart 
                data={dayData[chartTab]}
                height={325}
                onMouseLeave={() => {
                  setActiveData({x: '', y: ''})
                }}
                onMouseMove={(state:any) => {
                  setActiveData({x: state?.activeLabel ? moment(state?.activeLabel*1000).utc().format('MMM, DD, YYYY') : '', y: isEmpty(state?.activePayload) ? '' : state?.activePayload[0]?.value})
                }}
              />
            ) : null}
          </Grid>
        </StyledPaper>
      </Grid>
      <Grid className='mt-2.5' >
        <Typography>Transactions</Typography>
        <TransicationList data={transaction} showHeader={false} loading={txsLoading}/>
      </Grid>
    </Grid>
  )
}

export default Detail;