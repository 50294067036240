import React from 'react';
import Avatar, {AvatarProps} from '@mui/material/Avatar';
import defaultIcon from '../../assets/default-token.svg';
import { alpha, styled } from '@mui/material';

const StyledAvatar = styled(Avatar)<AvatarProps>(({theme}) => ({
  background: alpha(theme.palette.primary.main, 0.4),
  color: 'transparent',
}))

const TokenIcon = (props:any) => {
  const {size = 24, ...restProps} = props
  return (
    <StyledAvatar 
      {...restProps} 
      src={props.src || defaultIcon} 
      sx={{width: size, height: size, filter: props.src ? '' : ''}}
      component='div'
    ><img src={defaultIcon} alt=''/></StyledAvatar>
  )
}

export default TokenIcon