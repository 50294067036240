import { Token } from '@uniswap/sdk-core'
import { useNetwork } from 'wagmi'
import { useEffect, useMemo, useState } from 'react';
import { useCombinedActiveList, TokenAddressMap } from '../reducer/lists/hooks'
import Immutable, { Map, fromJS } from 'immutable';
import { TokenInfo } from '../types';
import {COMMON_BASES} from '../constants/routing';
import { isEmpty } from 'lodash';
import {useCurrentChainId} from '../hooks/useCurrentChainId';
import { useContractReads } from 'wagmi';
import erc20ABI from '../abis/erc20.json';
import { useAppSelector } from 'app/hooks';

function useTokensFromMap(tokenMap: TokenAddressMap, includeFetchedTokens: boolean = true): { [address: string]: Token } {
    const { chainId } = useCurrentChainId();
    const recommendToken = useMemo(() => {
      return chainId ? COMMON_BASES[chainId] : []
    }, [chainId]);
    const refactorRecommendTokens = recommendToken.reduce((refactorMap: any, token:any) => {
      return {
        ...refactorMap, 
        [token.isNative ? 'ETH' : token.address]: {
          ...token,
          tokenInfo: {
            ...token,
            address: token.isNative ? 'ETH' : token.address
          }
        }
      }
    }, {})

    return useMemo(() => {
      if (!chainId) return {}
      const mapWithoutUrls = Object.keys(tokenMap[chainId] ?? {}).reduce<{ [address: string]: Token }>(
        (newMap, address) => {
          newMap[address] = tokenMap[chainId][address].token
          return newMap
        },
        {...refactorRecommendTokens}
      )
      return mapWithoutUrls;
    }, [chainId, tokenMap, refactorRecommendTokens])
  }

export function useAllTokens(): { [address: string]: any } {
    const allTokens = useCombinedActiveList();
    return useTokensFromMap(allTokens, true)
}

export function useAllTokensMap(): Immutable.Map<string, TokenInfo>{
  const allTokens = useAllTokens();
  const { chainId } = useCurrentChainId();
  const fetchedTokens = useAppSelector(state => state.fetchedToken.fetchedTokens[chainId] || {})
  return useMemo(() => {
    if(isEmpty(allTokens)){
      return Map({});
    }
    const refactorTokenMap = Object.keys(allTokens ?? {})?.reduce((all:any, address: string) => {
      return {
        ...all, 
        [address]: allTokens[address]?.tokenInfo
      }
    },{});
    return Map({...fetchedTokens, ...refactorTokenMap,})
  },[allTokens, fetchedTokens])
}

export function useToken(tokenAddress: string): Token | any | null | undefined {
  const tokens = useAllTokens();
  const { data = [] } = useContractReads({
    contracts: [
      {
        addressOrName: tokenAddress,
        contractInterface: erc20ABI,
        functionName: 'name',
      },
      {
        addressOrName: tokenAddress,
        contractInterface: erc20ABI,
        functionName: 'symbol',
      },
    ]
  })
  const contractNameAndSymbol = useMemo(() => {
    if(isEmpty(data)){
      return {}
    }
    return {
      name: data[0],
      symbol: data[1],
      logoURI:''
    }
  }, [data]) 
  return useMemo(() => {
    if(!tokenAddress || isEmpty(tokens)){
      return null
    }
    return tokens[tokenAddress]?.tokenInfo || contractNameAndSymbol
  }, [tokens, tokenAddress, contractNameAndSymbol]);
}