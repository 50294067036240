import React from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { StyledText } from './styled-components';
import Badge from '@mui/material/Badge';
import { useBlockNumber } from 'hooks/useBlockNumber';

const LatestBlock = () => {
  const theme = useTheme();
  const blockNumber = useBlockNumber();
  return (
    <Grid className="flex items-center text-sm">
      <StyledText variant="body2" color={theme.palette.text.secondary} className="pr-0.5">Latest synced block</StyledText>
      <StyledText variant="body2" className="text-sm flex items-center" color={theme.palette.success.main}>{blockNumber}&nbsp;&nbsp;<Badge variant='dot' color="success" badgeContent=''/></StyledText>
    </Grid>
  )
}

export default LatestBlock;