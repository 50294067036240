import {useEffect, useState} from 'react';
import moment from 'moment';
import { useApi } from './useApi';

export function useTransaction(){
  const [transaction, setTransaction] = useState({all: [], swaps: [], placements: [], settlements: []});
  const [loading, setLoading] = useState(false);
  const api = useApi();
  useEffect(() => {
    if(!api){
      return 
    }
    setLoading(true)
    api.fetchLatestTransactions().then(({data}:any) => {
      const typedData = Object.entries(data).reduce((all, [type, itemMaps]) => {
        return {
          ...all, 
          [type]: (itemMaps as Array<any>)?.map((item:any) => ({...item, type}))
        }
      }, {swaps: [], placements: [], settlements: []})
      const refactorData = {
        ...typedData,
        all: [...typedData.swaps, ...typedData.placements, ...typedData.settlements].sort(
          (a:any, b:any) => moment(b?.block_timestamp).valueOf() - moment(a?.block_timestamp).valueOf()
        ),
      };
      setTransaction(refactorData);
      setLoading(false)
    })
  }, [api])
  return {
    transaction,
    loading
  }
}

export function useTransactionByAddress(type: string, address:string){
  const [transaction, setTransaction] = useState({all: [], swaps: [], placements: [], settlements: []});
  const api = useApi();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if(!api || !address || !type){
      return ;
    }
    setLoading(true);
    api.fetchLatestTransactionsByAddress(type, address).then(({data}:any) => {
      const typedData = Object.entries(data).reduce((all, [type, itemMaps]) => {
        return {
          ...all, 
          [type]: (itemMaps as Array<any>)?.map((item:any) => ({...item, type}))
        }
      }, {swaps: [], placements: [], settlements: []})
      const refactorData = {
        ...typedData,
        all: [...typedData.swaps, ...typedData.placements, ...typedData.settlements].sort(
          (a:any, b:any) => moment(b?.block_timestamp).valueOf() - moment(a?.block_timestamp).valueOf()
          ),
      };
        setTransaction(refactorData);
        setLoading(false)
    }).catch((err:any) => {
      setLoading(false)
    })
  }, [address, type, api])
  return {
    transaction,
    loading
  }
}