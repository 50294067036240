import * as React from 'react';
import {DataGrid, DataGridProps, GridColDef} from '@mui/x-data-grid';
import {styled, Stack, Typography} from '@mui/material';
import { DEFAULT_PAGE_SIZE } from "../constants";
import {alpha, useTheme} from '@mui/material/styles';
import NoData from './NoData';
import Pagination from '@mui/material/Pagination';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import ArrowPrev from '@mui/icons-material/ArrowBackIos';
import ArrowNext from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';



interface DataGridExtendProps extends DataGridProps {
  rows: Array<any>,
  columns: GridColDef[],
  height?: number,
  handleFetch?: any,
  fetchMoreOptions?: any,
  hideFooterPagination?: boolean,
  disableMultipleColumnsSorting?: boolean,
  size?:string,
}

const StyledDataGrid = styled(DataGrid)<DataGridExtendProps>(({theme, hideFooterPagination, rows, size='large'}) => ({
  background: alpha(theme.palette.background.paper, 0.8),
  border: `1px solid transparent`,
  borderRadius: theme.spacing(1),
  padding:`0 ${theme.spacing(0)} 8px`,
  '.MuiDataGrid-columnHeaders': {
    color: `${theme.palette.text.primary} !important`,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    border: 0,
    '.MuiDataGrid-columnHeader':{
      boxSizing: 'border-box',
      borderBottom: size==='large' ? `1px dashed ${alpha(theme.palette.divider, 0.3)}`: '0',
      height: 'auto !important',
      '&:first-of-type':{
        paddingLeft: theme.spacing(3)
      },
      '&:last-child':{
        paddingRight: theme.spacing(3)
      },
      padding: size==='small' ? `${theme.spacing(0.25)} ${theme.spacing(0)}` : `${theme.spacing(1.2)} ${theme.spacing(0)}`,
      '&:focus':{
        outline: 'none !important'
      },
      'button':{
        color: theme.palette.text.primary
      },
      "&:hover":{
        color: `${theme.palette.text.secondary} !important`,
      },
      '&.MuiDataGrid-columnHeader--sorted':{
        '.MuiDataGrid-columnHeaderTitleContainer': {
          color: `${theme.palette.text.secondary} !important`,
          'button':{
            color: `${theme.palette.text.secondary} !important`
          },
        },
      }
    },
  },
  '.MuiDataGrid-columnHeaderTitle':{
    lineHeight: 1,
    fontWeight: 400,
  },
  '.MuiDataGrid-columnHeadersInner': {
    borderBottom: 0,
  },
  '.MuiDataGrid-columnSeparator': {
    color: `${theme.palette.text.secondary} !important`,
    display: 'none'
  },
  '.MuiDataGrid-row':{
    '&:hover':{
      filter: 'opacity(60%)',
    }
  },
  '.MuiDataGrid-cell': {
    borderBottom: size==='large' ? `1px dashed ${alpha(theme.palette.divider, 0.3)}` : '0',
    fontWeight: 400,
    height: 'auto',
    lineHeight: 1,
    padding: 0,
    outline: 'none',
    boxShadow: '0 0 0 !important', 
    '&:first-of-type':{
      paddingLeft: theme.spacing(3)
    },
    '&:last-child':{
      paddingRight: theme.spacing(3)
    },
    '&:focus':{
      outline: 'none !important'
    }
  },
  '.MuiDataGrid-footerContainer': {
    borderTop: 0,
    minHeight: hideFooterPagination ? '0' : 'auto',
    justifyContent: 'center',
  },
  '.MuiDataGrid-virtualScroller':{
    minHeight: rows.length ? 'auto' : size === 'small' ? 'auto' : '300px',
  }
}))

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const theme = useTheme()

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      renderItem={(params:any) => {
        return (
          <div className='flex items-center'>
          {params?.type === 'previous' ? (
          <IconButton aria-label="delete" {...params} sx={{color: params.disabled ? theme.palette.text.secondary : theme.palette.text.primary }}>
            <ArrowPrev 
              fontSize="small" 
            />
          </IconButton>
          ) : null}
          {params?.type === 'page' && params.page === page+1? (
            <Typography color='text.secondary' className='px-2'><Typography color='text.primary' component='span' >{page+1}</Typography>/{pageCount}</Typography>
          ) : null}
          {params?.type === 'next' ? (
            <ArrowNext {...params} fontSize="small"  color={params.disabled ? 'text.secondary' : 'text.primary'}/>
          ) : null}
          </div>
        )
      }}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function DataTable(props: DataGridExtendProps) {
  const {
    handleFetch,
    fetchMoreOptions,
    rows,
    pageSize = DEFAULT_PAGE_SIZE,
    hideFooterPagination,
    size,
    ...resetProps
  } = props;
  const [page, setPage] = React.useState(0);
  const handlePageChanged = (currentPage: number) => {
    if (currentPage > page && handleFetch && (rows.length - (page+2) * pageSize) < pageSize) {
      handleFetch(currentPage);
    }
    setPage(currentPage);
  }

  React.useEffect(() => {
    setPage(0);
  }, [rows, setPage])

  return (
    <StyledDataGrid
      rows={rows}
      autoHeight={props.height ? false : true}
      pagination
      rowsPerPageOptions={[pageSize]}
      size={size}
      page={page}
      pageSize={pageSize}
      onPageChange={handlePageChanged}
      hideFooterPagination={hideFooterPagination}
      disableColumnSelector={true}
      disableSelectionOnClick={true}
      hideFooterSelectedRowCount={true}
      disableDensitySelector={true}
      disableColumnFilter={true}
      disableColumnMenu={true}
      rowHeight={size === 'small' ? 40 : 54}
      headerHeight={50}
      //localeText={}
      components={{
        NoRowsOverlay: () => (
          <NoData/>
        ),
        BaseTooltip: (props:any) => {
          return (
            <div>{props.children}</div>
          )
        },
        Pagination: !hideFooterPagination ? CustomPagination : null,
      }}
      {...resetProps}
    />
  );
}
