import { useEffect, useState, useMemo } from "react";
import {useApi} from './useApi';
import {isEmpty} from 'lodash';

export function usePool(address:string){
  const [poolInfo, setPool] = useState<any>({})
  const api = useApi();
  useEffect(() => {
    if(!address || !api){
      return ;
    }
    api.fetchPoolByAddress(address).then(({data} :any) => {
      setPool(data.overview);
    })
  },[address, api])
  return poolInfo
}

export function useTicker(query:any){
  const {base = '', quote = '', resolution = 0} = query
  const [ticker, setState] = useState<any>({})
  const api = useApi();
  useEffect(() => {
    if(!base || !quote || !resolution || !api){
      return ;
    }
    api.fetchTicker({base, quote, resolution}).then(({data}:any) => {
      setState(data);
    })
  },[base, quote, resolution, api])
  return ticker
}

export function usePoolChartData(address:string){
  const [data, setState] = useState<any>({})
  const api = useApi();
  useEffect(() => {
    if(!address || !api){
      return ;
    }
    api.fetchPoolChartData({address: address}).then(({data}:any) => {
      const {day_data = []} = data
      const refactorData = day_data?.reduce((all: {tvl: [], volume: [], fees: []}, current:any) => {
        return {
          ...all, 
          tvl: [
            ...all.tvl, 
            {
              ...current,
              x: current.date,
              y: Number(current.tvl_usd)
            }
          ],
          volume: [...all.volume, {
              ...current,
              x: current.date,
              y: Number(current.volume_usd)
            }
          ],
          fees: [...all.fees, {
              ...current,
              x: current.date,
              y: Number(current.fees_usd)
            }
          ]
        }
      }, {tvl: [], volume: [], fees: []})
      setState(refactorData);
    })
  },[address, api])
  return useMemo(() =>{ return data}, [data])
}