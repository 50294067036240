import { createSlice, Reducer, createAsyncThunk } from "@reduxjs/toolkit";
import api from '../api';
import Decimal from 'decimal.js';
import moment from 'moment';

interface StateProps {
  list: Array<any>,
  overview: {
    tvl: ChartDataProps[],
    volume:{
      [key:string]: ChartDataProps[],
    }
  },
  overview24: any
}

interface ChartDataProps {
  date?: number, 
  value?: number,
  x: number, 
  y: number | string,
  from? :number,
  to?: number
}

const initialState: StateProps = {
  list: [],
  overview: {
    tvl: [],
    volume: {
      day: [],
      week: [],
      month: [],
    }
  },
  overview24:{}
};


export const overview = createSlice({
  name: "overview",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
  }
});

export default overview.reducer as Reducer<typeof initialState>;
