export function computeFeeByTickSpacing(tickSpacing: number): number {
  return tickSpacing / 100;
}

export function toFormattedFee(tickSpacing: number | undefined, percent: boolean = true): string {
  if (tickSpacing) {
    const value = computeFeeByTickSpacing(tickSpacing);
    return percent ? `${value}%` : String(value);
  } else {
    return '-';
  }
}

export function getFeeAmountByTickSpacing(tickSpacing: number) {
  return tickSpacing * 100;
}
