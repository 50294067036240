import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LatestBlock from '../components/latestBlock';
import { StyledPaper } from '../components/styled-components';
import TokenPrice from '../components/token-price';
import {useTransaction} from '../../hooks/useTranscation';
import { decimalNumber } from '../../utils';
import TransicationList from '../../components/transication-list';
import Pools from '../components/pools';
import Tokens from '../components/tokens';
import { useTopPools } from 'hooks/useTopPools';
import { useTopTokens } from 'hooks/useTopTokens';
import {useGolbalOverview24H} from '../../hooks/useGlobalOverview';

const Overview = () => {
  const { transaction, loading } = useTransaction();
  const overview24h = useGolbalOverview24H()
  const { pools, loading: poolLoading } = useTopPools();
  const {tokens, loading: tokenLoading} = useTopTokens()
  return (
    <div>
      <Grid className="flex justify-end items-center">
        <LatestBlock/>
      </Grid>
      <StyledPaper className="py-1.25 px-1.5 mt-1.25 flex">
        <Grid className="flex items-center">
          <Typography color="text.secondary" className="text-base" >Volume 24H:</Typography>
          <TokenPrice 
            className="pl-0.5" 
            value={`$${decimalNumber(overview24h?.volume_usd_24h)}`} 
            delta={overview24h?.volume_usd_change_24h}
            type="volume_usd_change_24h"
          />
        </Grid>
        <Grid className="flex items-center ml-2">
          <Typography color="text.secondary" className="text-base">Total Volume:</Typography>
          <TokenPrice className="pl-0.5" value={`$${decimalNumber(overview24h?.total_volume_usd)}`} showDelta={false}/>
        </Grid>
        <Grid className="flex items-center ml-2">
          <Typography color="text.secondary" className="text-base">Fees 24H:</Typography>
          <TokenPrice 
            className="pl-0.5"
            value={`$${decimalNumber(overview24h?.fees_usd_24h)}`} 
            delta={overview24h?.fees_usd_change_24h}
            type="fees_usd_change_24h"
          />
        </Grid>
        <Grid className="flex items-center ml-2">
          <Typography color="text.secondary" className="text-base">Total Fees:</Typography>
          <TokenPrice className="pl-0.5" value={`$${decimalNumber(overview24h?.total_fees_usd)}`} showDelta={false}/>
        </Grid>
      </StyledPaper>
      <Grid className="mt-2.5">
        <Tokens title="Top Tokens" data={tokens} to="/overview/tokens" loading={tokenLoading}/>
      </Grid>
      <Grid className="mt-2.5">
        <Pools title="Top Pairs" pools={pools} to="/overview/pools" loading={poolLoading}/>
      </Grid>
      <Grid className="mt-2.5">
        <Grid className="flex justify-between">
          <Typography>Transactions</Typography>
        </Grid>
        <TransicationList data={transaction} showHeader={false} loading={loading}/>
      </Grid>
    </div>
  )
}

export default Overview;