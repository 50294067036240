import { createSlice, Reducer, createAsyncThunk } from "@reduxjs/toolkit";
import api from '../api';
import { RootState } from '../app/store';
import {ethers} from 'ethers';
import { RPC_PROVIDERS } from '../constants/networks';
import {erc20ABI} from 'wagmi';

interface StateProps {
  fetchedTokens: {
    [address:string]: any
  }
}


const initialState: StateProps = {
  fetchedTokens: {}
};


export const fetchTokensMeta = createAsyncThunk(
  'tokens/fetch-token-meta',
  async (address:string , thunkAPI): Promise<any> => {
    const roorState = thunkAPI.getState() as RootState
    const chainId = Number(roorState.global.chain.id);
    const contract = new ethers.Contract(address, erc20ABI, RPC_PROVIDERS[chainId]);
    const name = await contract.name();
    const symbol = await contract.symbol();
    const decimals = await contract.decimals();
    return {chainId, tokens: {[address]: {address, name: name || 'Unknown Token', symbol: symbol || 'UNKNOWN', decimals, chainId}}}
  }
)


export const fetchedToken = createSlice({
  name: "fetchedToken",
  initialState,
  reducers: {
    setFetchedTokens(state, { payload }){
      const {chainId, tokenMeta} = payload ;
      if(state.fetchedTokens[chainId]){
        state.fetchedTokens[chainId] = {
          ...state.fetchedTokens[chainId],
          ...tokenMeta
        }
      }else{
        state.fetchedTokens = {
          ...state.fetchedTokens,
          [chainId]: tokenMeta
        }
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchTokensMeta.fulfilled, (state, { payload }) => {
      const {chainId, tokens} = payload ;
      if(state.fetchedTokens[chainId]){
        state.fetchedTokens[chainId] = {
          ...state.fetchedTokens[chainId],
          ...tokens
        }
      }else{
        state.fetchedTokens = {
          ...state.fetchedTokens,
          [chainId]: tokens
        }
      }
    })
  },
});

export const { setFetchedTokens } = fetchedToken.actions;


export default fetchedToken.reducer as Reducer<typeof initialState>;
