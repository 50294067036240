import { combineReducers, configureStore, ThunkAction, Action, AnyAction} from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import autoMergeLevel2 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import personalReducer from '../reducer/personal';
import listsReducer from '../reducer/lists/reducer';
import overviewReducer from '../reducer/overview';
import tokenReducer from '../reducer/token';
import poolReducer from '../reducer/pool';
import fetchedTokensReducer from '../reducer/fetchedToken';
import watchlistReducer from '../reducer/watchlist';
import globalReducer from '../reducer/global/slice';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['fetchedToken', 'watchlist', 'global'],
};

const rootReducers = combineReducers({
  personal: personalReducer,
  overview: overviewReducer,
  token: tokenReducer,
  pool: poolReducer,
  lists: listsReducer,
  fetchedToken: fetchedTokensReducer,
  watchlist: watchlistReducer,
  global: globalReducer
})

const _persistedReducer = persistReducer<any,AnyAction>(persistConfig, rootReducers);

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  },
  devTools: process.env.NODE_ENV !== 'production',
});

//setupListeners(store.dispatch)
export type RootState = ReturnType<typeof rootReducers>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  any,
  Action<string>
>;
export interface ActionReducer<T, V extends Action = Action> {
  (state: T | undefined, action: V): T;
}

//store.dispatch(updateVersion());

