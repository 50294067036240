import React from 'react';
import Typography, {TypographyProps} from '@mui/material/Typography';
import {styled, alpha} from '@mui/material/styles';
import Paper, {PaperProps} from '@mui/material/Paper';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

const StyledPaper = styled(Paper)<PaperProps>(({theme}) => ({
  width: '100%', 
  borderRadius: theme.spacing(1),
  background: alpha(theme.palette.background.paper, 0.8)
}))

interface ExtendTypographyProps extends TypographyProps{
  color?: string
}

const StyledComponent = styled('span')<any>(({color}) => ({
  color: color
}))

const StyledText = (props: any) => {
  const {children, ...restProps} = props
  return (
    <StyledComponent {...restProps}>{children}</StyledComponent>
  )
}

const StyledExpolor = (props: any) => {
  return (
    <Typography component={Link} to={props.to} color="text.secondary">Explore <NavigateNext color="inherit" fontSize="inherit"/></Typography>
  )
}

const StyledPrice = styled('span')<any>(({theme, delta = 0}) => ({
  color: Number(delta) === 0 ? theme.palette.text.secondary : Number(delta) > 0 ? theme.palette.success.main : theme.palette.error.main
}))


export { StyledText, StyledPaper, StyledExpolor, StyledPrice};