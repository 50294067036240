export const USDC_GOERLI = {
  "name": "USDCoin",
  "address": "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
  "symbol": "USDC",
  "decimals": 6,
  "chainId": 5,
  "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  "extensions": {
    "bridgeInfo": {
      "1": {
        "tokenAddress": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
      }
    }
  }
};

export const TEST_GOERLI = {
  "name": "USDCoin",
  "address": "0x5DB7C87BC6E5E20FBC02328FD26226ADB1179A68",
  "symbol": "KENT",
  "decimals": 8,
  "chainId": 5,
  "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
}

export const YUSDT_GOERLI = {
  "name": "YUSDT",
  "address": "0x303b35f48684bea50D0e7D1AcDdeaf78A7188798",
  "symbol": "YUSDT",
  "decimals": 8,
  "chainId": 5,
  "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
}

export const YETH_GOERLI = {
  "name": "YETH",
  "address": "0x3aF186815b1684042155d36C86123c91A7a221bF",
  "symbol": "YETH",
  "decimals": 18,
  "chainId": 5,
  "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/512.png",
}

export const WETH_GOERLI = {
  "name": "Wrapped Ether",
  "address": "0x1763Dd074ee4052273f5f314F0F87De4d93e9b45",
  "symbol": "WETH",
  "decimals": 18,
  "chainId": 5,
  "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  "extensions": {
    "bridgeInfo": {
      "42161": {
        "tokenAddress": "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
      }
    }
  }
};

export const YUNI_GOERLI = {
  "name": "YUNI",
  "address": "0x12cDc7333232F7e843AA5DB94bd38858644cf0A1",
  "symbol": "YUNI",
  "decimals": 18,
  "chainId": 5,
  "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png",
}

export const UNI_GOERLI = {
  "name": "Uni",
  "address": "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
  "symbol": "UNI",
  "decimals": 18,
  "chainId": 5,
  "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png"
};

export const DGD = {
  "name": "DGD",
  "address": "0xE0B7927c4aF23765Cb51314A0E0521A9645F0E2A",
  "symbol": "DGD",
  "decimals": 9,
  "chainId": 5,
  "logoURI": "https://etherscan.io/token/images/digix_dgd_28.png"
};

export const AAVE = {
  "name": "Aave Token",
  "address": "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
  "symbol": "AAVE",
  "decimals": 18,
  "chainId": 1,
  "logoURI": "https://etherscan.io/token/images/aave_32.png"
};

export const LIF = {
  "name": "Lif",
  "address": "0xEB9951021698B42e4399f9cBb6267Aa35F82D59D",
  "symbol": "LIF",
  "decimals": 18,
  "chainId": 1,
  "logoURI": ""
};

export const SVD = {
  "name": "savedroid",
  "address": "0xbdEB4b83251Fb146687fa19D1C660F99411eefe3",
  "symbol": "SVD",
  "decimals": 18,
  "chainId": 1,
  "logoURI": "https://etherscan.io/token/images/savedroid_28.png"
};

export const TheDAO = {
  "name": "TheDAO",
  "address": "0xBB9bc244D798123fDe783fCc1C72d3Bb8C189413",
  "symbol": "TheDAO",
  "decimals": 18,
  "chainId": 1,
  "logoURI": "https://etherscan.io/token/images/thedao-logo.png"
};

export const HPB = {
  "name": "HPB",
  "address": "0x38c6A68304cdEfb9BEc48BbFaABA5C5B47818bb2",
  "symbol": "HPBCoin",
  "decimals": 18,
  "chainId": 1,
  "logoURI": "https://etherscan.io/token/images/hpbcoin_28.png"
};

export const CLARK_GOERLI = {
  "name": "ClarkCoin",
  "address": "0x45be9E73D2FF82d2d5734a886364f561c7a170BF",
  "symbol": "CLARK",
  "decimals": 18,
  "chainId": 5,
  "logoURI": ""
};
