import Immutable from "immutable";
import {
  arbitrumRinkeby,
  optimismKovan,
  polygonMumbai,
  rinkeby as defaultRinkeby,
  ropsten as defaultRopsten,
  mainnet as defaultMainnet,
  goerli as defaultGoerli,
  arbitrum,
  optimism,
  polygon,
} from "wagmi/chains";
import { Chain } from "wagmi";

export const avalandche: Chain = {
  id: 43114,
  name: "Avalanche C-Chain",
  network: "avalanche",
  rpcUrls: {
    default: "https://rpc.ankr.com/avalanche",
  },
  nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  blockExplorers: {
    default: {
      name: "snowtrace",
      url: "https://snowtrace.io/",
    },
  },
};

export const avalandcheFuji: Chain = {
  id: 43113,
  name: "Avalanche Fuji",
  network: "avalanche-fuji",
  rpcUrls: {
    default: "https://rpc.ankr.com/avalanche_fuji",
  },
  nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  blockExplorers: {
    default: {
      name: "snowtrace",
      url: "https://testnet.snowtrace.io/",
    },
  },
  testnet: true,
};

export const fantomOpera: Chain = {
  id: 250,
  name: "Fantom Opera",
  network: "fantom",
  nativeCurrency: { name: "Fantom", symbol: "FTM", decimals: 18 },
  rpcUrls: {
    default: "https://rpc.ftm.tools",
  },
  blockExplorers: {
    default: {
      name: "FTMScan",
      url: "https://ftmscan.com",
    },
  },
};

export const fantomTestnet: Chain = {
  id: 4002,
  name: "Fantom Testnet",
  network: "fantom-testnet",
  nativeCurrency: { name: "Fantom", symbol: "FTM", decimals: 18 },
  rpcUrls: {
    default: "https://rpc.testnet.fantom.network",
  },
  blockExplorers: {
    default: {
      name: "FTMScan",
      url: "https://testnet.ftmscan.com",
    },
  },
  testnet: true,
};

const bscExplorer = { name: "BscScan", url: "https://bscscan.com" };

export const bsc: Chain = {
  id: 56,
  name: "BNB Smart Chain",
  network: "bsc",
  rpcUrls: {
    default: "https://bsc-dataseed1.binance.org",
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18,
  },
  multicall: {
    address: "0x72dba3Fa54C73D9EDB493e9F4eDf884439B1eBC4",
    blockCreated: 20455688,
  },
};

export const bscTest: Chain = {
  id: 97,
  name: "BNB Smart Chain Testnet",
  network: "bsc-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Binance Chain Native Token",
    symbol: "tBNB",
  },
  rpcUrls: {
    default: "https://data-seed-prebsc-1-s2.binance.org:8545/",
    // https://docs.nodereal.io/nodereal/meganode/meganode-api-overview/public-api-key
    nodeReal:
      "https://bsc-testnet.nodereal.io/v1/e9a36765eb8a40b9bd12e680a1fd2bc5",
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://testnet.bscscan.com" },
  },
  multicall: {
    address: "0xb66a4fE12138C4391A98F29E34EFE4Cc7A445AE5",
    blockCreated: 21965366,
  },
  testnet: true,
};

export const ropsten: Chain = {
  ...defaultRopsten,
  rpcUrls: {
    ...defaultRopsten.rpcUrls,
    nodeReal: "https://ropsten.infura.io/v3/4a5863529c7d4009b4a58dcf7c683165",
  },
};
export const rinkeby: Chain = {
  ...defaultRinkeby,
  rpcUrls: {
    ...defaultRinkeby.rpcUrls,
    // https://docs.nodereal.io/nodereal/meganode/meganode-api-overview/public-api-key
    nodeReal:
      "https://eth-rinkeby.nodereal.io/v1/a4da384bf3334c5ea992eb0bf44135e0",
  },
  multicall: {
    address: '0xca11bde05977b3631167028862be2a173976ca11',
    blockCreated: 12984533
  },
};

export const mainnet: Chain = {
  ...defaultMainnet,
  rpcUrls: {
    ...defaultMainnet.rpcUrls,
    // https://docs.nodereal.io/nodereal/meganode/meganode-api-overview/public-api-key
    nodeReal:
      "https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7",
  },
};

export const goerli: Chain = {
  ...defaultGoerli,
  rpcUrls: {
    ...defaultGoerli.rpcUrls,
    // https://docs.nodereal.io/nodereal/meganode/meganode-api-overview/public-api-key
    nodeReal:
      `https://goerli.infura.io/v3/${process.env.REACT_APP_PUBLIC_INFRUA}`,
  },
  // multicall: {
  //   address: '0x31F86131E096AdaaBf4782D06dbCbc2513A660B0',
  //   blockCreated: 7594412
  // },
};

export const CHAINS_TESTNET = [
  // bscTest,
  ropsten,
  rinkeby,
  arbitrumRinkeby,
  optimismKovan,
  polygonMumbai,
  avalandcheFuji,
  fantomTestnet,
];

export const SUPPORTED_CHAINS = [
  mainnet,
  // bscTest,
  // ropsten,
  // rinkeby,
  goerli,
];
export const SUPPORTED_CHAINS_MAP = Immutable.fromJS(SUPPORTED_CHAINS);
export const SUPPORTED_CHAIN_IDS = SUPPORTED_CHAINS.map((c) => c.id);
export const CHAINS_STARGATE = [
  mainnet,
  arbitrum,
  optimism,
  polygon,
  avalandche,
  fantomOpera,
];
