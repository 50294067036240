import api from '../api';
import {useEffect, useState, useMemo} from 'react';
import {useAllTokensMap} from './useAllTokens';
import {useCurrentChainId} from './useCurrentChainId'
import { useAppDispatch } from 'app/hooks';
import { isEmpty, stubFalse } from 'lodash';
import {useFetchTokenMetas} from './useTokenMeta';
import {setFetchedTokens} from '../reducer/fetchedToken';
import { useBlockNumber, useChainedBlockNumber } from './useBlockNumber';
import { useApi } from './useApi';

export function useTopPools(){
  const [pools, setState] = useState([]);
  const allTokens = useAllTokensMap();
  const { chainId } = useCurrentChainId();
  const dispatch = useAppDispatch()
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const allTokenAddress = useMemo(() => {
    if(!allTokens.size){
      return [];
    }
    return allTokens.map((item:any) => item.address).toArray();
  }, [allTokens])
  const needFetchTokens = useMemo(() => {
    if(isEmpty(allTokenAddress) || isEmpty(pools)) {
      return [];
    }
    const adddress = pools.filter((item :any) => !allTokenAddress.includes(item?.token0) || !allTokenAddress.includes(item.token1)).map((item:any) => [item.token0, item.token1]).flat()
    return Array.from(new Set(adddress))
  },[pools, allTokenAddress])
  const tokenMeta:any = useFetchTokenMetas(needFetchTokens);
  useEffect(() => {
    if(isEmpty(tokenMeta)){
      return ;
    }
    dispatch(setFetchedTokens({chainId, tokenMeta}))
  }, [tokenMeta, chainId, dispatch])
  useEffect(() => {
    if(!api){
      return ;
    }
    setLoading(true)
    api.fetchTopPools().then(({data}:any) => {
      setState(data.grids.map((item:any, index:number) => ({...item, id: index+1})));
      setLoading(false)
    }).catch((err:any) => {
      setLoading(false)
    })
  }, [api])
  return {
    pools: pools.map((item:any) => ({
      ...item,
      fees_usd: Number(item.fees_usd),
      price_change_24h: Number(item.price_change_24h),
      volume_usd_24h: Number(item.volume_usd_24h),
      volume_usd_7d: Number(item.volume_usd_7d),
    })),
    loading
  }
}


export function usePoolsByToken(address: string){
  const [pools, setState] = useState([]);
  const allTokens = useAllTokensMap();
  const { chainId } = useCurrentChainId();
  const dispatch = useAppDispatch()
  const api = useApi()
  const [loading, setLoading] = useState(true);
  const allTokenAddress = useMemo(() => {
    if(!allTokens.size){
      return [];
    }
    return allTokens.map((item:any) => item.address).toArray();
  }, [allTokens])
  const needFetchTokens = useMemo(() => {
    if(isEmpty(allTokenAddress) || isEmpty(pools)) {
      return [];
    }
    const adddress = pools.filter((item :any) => !allTokenAddress.includes(item?.token0) || !allTokenAddress.includes(item.token1)).map((item:any) => [item.token0, item.token1]).flat()
    return Array.from(new Set(adddress))
  },[pools, allTokenAddress])
  const tokenMeta:any = useFetchTokenMetas(needFetchTokens);
  useEffect(() => {
    if(isEmpty(tokenMeta)){
      return ;
    }
    dispatch(setFetchedTokens({chainId, tokenMeta}))
  }, [tokenMeta, chainId, dispatch])
  useEffect(() => {
    if(!address || !api){
      return ;
    }
    setLoading(true)
    api.fetchPoolsByToken(address).then((resp: any) => {
      const {data} = resp;
      setState(data.pools.map((item:any, index:number) => ({...item, id: index+1})));
      setLoading(false)
    }).catch((err:any) => {
      setLoading(false)
    })
  }, [address, api])
  return {
    pools: pools.map((item:any) => ({
      ...item,
      price_change_24h: Number(item.price_change_24h),
      volume_usd_24h: Number(item.volume_usd_24h),
      volume_usd_7d: Number(item.volume_usd_7d),
    })),
    loading
  }
}


export function usePoolsByAddresses(addresses: string[]){
  const [pools, setState] = useState([]);
  const allTokens = useAllTokensMap();
  const { chainId } = useCurrentChainId();
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true);
  const api = useApi();
  const allTokenAddress = useMemo(() => {
    if(!allTokens.size){
      return [];
    }
    return allTokens.map((item:any) => item.address).toArray();
  }, [allTokens])
  const needFetchTokens = useMemo(() => {
    if(isEmpty(allTokenAddress) || isEmpty(pools)) {
      return [];
    }
    const adddress = pools.filter((item :any) => !allTokenAddress.includes(item?.token0) || !allTokenAddress.includes(item.token1)).map((item:any) => [item.token0, item.token1]).flat()
    return Array.from(new Set(adddress))
  },[pools, allTokenAddress])
  const tokenMeta:any = useFetchTokenMetas(needFetchTokens);
  useEffect(() => {
    if(isEmpty(tokenMeta)){
      return ;
    }
    dispatch(setFetchedTokens({chainId, tokenMeta}))
  }, [tokenMeta, chainId, dispatch])
  useEffect(() => {
    if(isEmpty(addresses) || !api){
      return ;
    }
    setLoading(true)
    api.fetchPools(addresses).then((resp: any) => {
      const {data} = resp;
      setState(data.overviews.map((item:any, index:number) => ({...item, id: index+1})));
      setLoading(false)
    }).catch((err:any) => {
      setLoading(false)
    })
  }, [addresses, api])
  const returnPools = useMemo(() => {
    return isEmpty(addresses) ? []: pools.map((item:any) => ({
      ...item,
      price_change_24h: Number(item.price_change_24h),
      volume_usd_24h: Number(item.volume_usd_24h),
      volume_usd_7d: Number(item.volume_usd_7d),
    }))
  },[pools, addresses])
  return {
    pools: returnPools,
    loading
  }
}

