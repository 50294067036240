export const TICK_SPACINGS = {
  100: 1,
  1000: 10,
  5000: 50,
};

export enum Fee_Amount {
  LOW = 100,
  MEDIUM = 1000,
  HIGH = 5000,
}

export enum Tick_Spacing {
  LOW = 1,
  MEDIUM = 10,
  HIGH = 50,
}

export interface IFeeItem {
  label: string;
  value: Fee_Amount;
  description: string;
}

export const FEE_AMOUNT_DETAIL: Record<Fee_Amount, IFeeItem> = {
  [Fee_Amount.LOW]: {
    label: "0.01",
    value: Fee_Amount.LOW,
    description: "Best for stable pairs.",
  },
  [Fee_Amount.MEDIUM]: {
    label: "0.1",
    value: Fee_Amount.MEDIUM,
    description: "Best for most pairs.",
  },
  [Fee_Amount.HIGH]: {
    label: "0.5",
    value: Fee_Amount.HIGH,
    description: "Best for exotic pairs.",
  },
};

export const FEE_LIST:Array<IFeeItem> = [
  Fee_Amount.LOW,
  Fee_Amount.MEDIUM,
  Fee_Amount.HIGH
].map(
  (item) => {
    return FEE_AMOUNT_DETAIL[item];
  }
);
