import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { persistStore } from "reduxjs-toolkit-persist";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Loading from "./components/loading";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { WagmiProvider } from "./constants/connectors/provider";
import { client } from "./constants/connectors/wagmi";
const root = createRoot(document.getElementById("root") as HTMLElement);

if (typeof (window as any).global === "undefined") {
  (window as any).global = window;
}

root.render(
  <Suspense fallback={<Loading />}>
    <WagmiProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persistStore(store)}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </WagmiProvider>
  </Suspense>
);
