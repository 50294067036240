import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { StyledPaper } from '../components/styled-components';
import { useParams, Link } from 'react-router-dom';
import TokenIcon from '../components/token-icon';
import Button from '@mui/material/Button';
import { Star } from '../../components/svg-icon';
import {useTheme, styled} from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Launch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AreaChart from '../../components/chart/area';
import { useTokenInfo, useTokenChartData } from '../../hooks/useToken';
import { decimalNumber, ExplorerDataType, getExplorerLink, percentValue, pricePrecision, shortenAddress } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ColumnChart from '../../components/chart/column';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useAllTokensMap } from '../../hooks/useAllTokens';
import Pools from '../components/pools';
import TransicationList from '../../components/transication-list';
import { useTransactionByAddress } from 'hooks/useTranscation';
import { usePoolsByToken } from 'hooks/useTopPools';
import { hasTokenWatched } from 'reducer/watchlist';
import { useCurrentChainId } from 'hooks/useCurrentChainId';
import TVChartContainer from 'components/tv-chart';
import { fetchTokensMeta } from 'reducer/fetchedToken';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Paper, {PaperProps} from '@mui/material/Paper';
import Box from '@mui/material/Box';
import StarComponent from '../components/star';
import { DEFAULT_USER_PRECISION } from '../../constants';


const StyledTicker = styled(Paper)<PaperProps>(({theme}) => ({
  '.inner':{
    background: `linear-gradient(90.03deg, rgba(135, 163, 217, 0.16) 0%, #000 100%)`,
    position: 'relative',
    height: 54,
  }
}))


const Detail = () => {
  const { token } = useParams() as {token: string};
  const theme = useTheme();
  const [chartTab, setChartTab] = useState('volume')
  const tokenInfo = useTokenInfo(token);
  const dispatch = useAppDispatch();
  const dayData = useTokenChartData(token)
  const tokenWatched = useAppSelector(hasTokenWatched(token));
  const tvTheme = useAppSelector(state =>  state.personal.theme);
  const [activeData, setActiveData] = useState({x: '', y: ''})
  const allTokens = useAllTokensMap();
  const tokenMeta = useMemo(() => {
    return allTokens.get(token) || {}
  }, [allTokens, token])
  const {transaction, loading: txsLoading} = useTransactionByAddress('tokens', token)
  const { pools, loading } = usePoolsByToken(token)
  const {chainId} = useCurrentChainId();
  const {chain} = useCurrentChainId()
  useEffect(() => {
    if(!isEmpty(tokenMeta) || !token){
      return
    }
    dispatch(fetchTokensMeta(token));
  }, [tokenMeta, dispatch, token])
  const chartValue = useMemo(() => {
    return chartTab === 'price' ? `$${decimalNumber(tokenInfo.price_usd)}`: `${ activeData.x ? `$${decimalNumber(activeData.y)}` : dayData[chartTab]?.length ? `$${decimalNumber(dayData[chartTab].slice(-1)[0].y)}` : 0}`
  },[chartTab, tokenInfo, dayData, activeData])

  const linePriceScale = useMemo(() => {
    return !isEmpty(tokenInfo) ? pricePrecision(tokenInfo?.price_usd) : DEFAULT_USER_PRECISION
  },[tokenInfo])
  return (
    <Grid>
      <Breadcrumbs aria-label="breadcrumb" className="relative pb-2.5">
        <Typography variant="body1" component={Link} color="text.secondary" to='/overview'>
          Home
        </Typography>
        <Typography variant="body1" color="text.secondary" component={Link} to='/tokens'>
          Tokens
        </Typography>
        <Typography variant="body1" color="text.primary">
          {tokenMeta?.symbol}
        </Typography>
      </Breadcrumbs>
      <Grid>
        <Grid>
          <Grid className="flex">
            <TokenIcon src={tokenMeta?.logoURI} />
            <div className="font-bold ml-0.5">{tokenMeta?.name} ({tokenMeta?.symbol})</div>
            <Grid className="flex items-center" component="a" href={getExplorerLink(chain, token, ExplorerDataType.ADDRESS)}>
              <Typography color="primary" variant="caption" className="pl-0.5" component="div">{shortenAddress(token)}</Typography>
              <ShareIcon className='ml-0.5' sx={{fontSize: 16}}/>
            </Grid>
            <StarComponent watched={tokenWatched} type='token' address={token}/>
          </Grid>
        </Grid>
      </Grid>
      <StyledTicker className="mt-2 relative">
        <div className='inner'/>
        <div className="pl-1.25 py-0.75 absolute top-0 left-0 flex justify-between w-full h-full">
          <Grid className="flex items-center">
            <Box>
              <div className='font-bold text-sm'>${decimalNumber(tokenInfo?.price_usd, 4)}</div>
            </Box>
            <Box className="ml-1.25">
              <Typography color={Number(tokenInfo?.price_change_24h) === 0  ? 'text.primary' : Number(tokenInfo?.price_change_24h) > 0 ? 'success.main' : 'error.main'} variant="caption" component="div">{percentValue(tokenInfo?.price_change_24h, false)}</Typography>
              <Typography component="div" variant="caption" color="text.secondary">Change 24h</Typography>
            </Box>
            <Box className="ml-1.25">
              <div className="text-xs">${decimalNumber(tokenInfo?.volume_usd_24h, 4)}</div>
              <Typography variant="caption" color="text.secondary" component="div" >Volume 24h</Typography>
            </Box>
            <Box className="ml-1.25">
              <div className="text-xs">${decimalNumber(tokenInfo?.volume_usd_7d, 4)}</div>
              <Typography variant="caption" color="text.secondary" component="div" >Volume 7d</Typography>
            </Box>
            <Box className="ml-1.25">
              <div className="text-xs">${decimalNumber(tokenInfo?.fees_24h, 4)}</div>
              <Typography variant="caption" color="text.secondary" component="div" >24 Fees</Typography>
            </Box>
          </Grid>
          <Grid className="flex justify-between items-center relative">
            <Button variant="contained" color="primary" sx={{marginLeft: theme.spacing(1)}} component="a" href={`https://app.cloverswap.org/trade/${token}`}>Trade</Button>
          </Grid>
        </div>
      </StyledTicker>
      <Grid className="flex mt-2">
        <StyledPaper className="h-full flex-1 px-2.25 py-1.25">
          <Grid className='flex justify-between items-start'>
            <Grid className='h-[60px]'>
              <Typography variant='h5' noWrap component='div'>{ chartValue }</Typography>
              <Typography variant='caption' component="div" color='text.secondary'>{activeData.x ? `${activeData.x}(UTC)` : ''}</Typography>
            </Grid>
            <ToggleButtonGroup
              value={chartTab}
              exclusive
              onChange={(e, value) => { setChartTab(value) }}
            >
              <ToggleButton value="volume">Volume</ToggleButton>
              <ToggleButton value="price">price</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid style={{height: 322}}>
            {chartTab === 'tvl' ? (
              <AreaChart 
                data={dayData[chartTab]}
                height={322}
                onMouseLeave={() => {
                  setActiveData({x: '', y: ''})
                }}
                onMouseMove={(state:any) => {
                  setActiveData({x: state?.activeLabel ? moment(state?.activeLabel*1000).utc().format('MMM, DD, YYYY') : '', y: isEmpty(state?.activePayload) ? '' : state?.activePayload[0]?.value})
                }}
              />
            ) : null}
            {chartTab === 'volume' ? (
              <ColumnChart 
                data={dayData[chartTab]}
                height={322}
                onMouseLeave={() => {
                  setActiveData({x: '', y: ''})
                }}
                onMouseMove={(state:any) => {
                  setActiveData({x: state?.activeLabel ? moment(state?.activeLabel*1000).utc().format('MMM, DD, YYYY') : '', y: isEmpty(state?.activePayload) ? '' : state?.activePayload[0]?.value})
                }}
              />
            ) : null}
            {chartTab === 'price' ? (
              <TVChartContainer 
                symbol={`TOKEN-${token}`}
                theme={tvTheme}
                themes={theme}
                chainId={chainId}
                pricescale={linePriceScale}
              />
            ) : null}
          </Grid>
        </StyledPaper>
      </Grid>
      <Grid className="mt-2.5">
        <Pools title="Pairs" pools={pools} loading={loading}/>
      </Grid>
      <Grid className="mt-2.5">
        <Grid className="flex justify-between">
          <Typography component='div'>Transactions</Typography>
        </Grid>
        <TransicationList data={transaction} showHeader={false} loading={txsLoading}/>
      </Grid>
    </Grid>
  )
}

export default Detail;