import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import MenuItem, {MenuItemProps} from "@mui/material/MenuItem";
// @ts-ignore
import ethSvg from "../../assets/eth-icon.png";
import { SUPPORTED_CHAINS } from "../../constants/connectors/chains";
import Paper from '@mui/material/Paper';
import { Typography, styled } from "@mui/material";
import Popover, {PopoverProps} from "@mui/material/Popover";
import { useCurrentChainId } from "hooks/useCurrentChainId";
import { useAppDispatch } from "app/hooks";
import { setChain } from '../../reducer/global/slice';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ethIcon from '../../assets/eth-icon.png';
import Badge from "@mui/material/Badge";
import { useLocation, useNavigate } from "react-router";
import qs from 'qs';
import { isEmpty } from "lodash";

const StyledMenu = styled(MenuItem)<MenuItemProps>(({theme}) => ({
  padding: `${theme.spacing(0.875)} ${theme.spacing(2.5)}`
}))

export default function HeaderAccount() {
  const { chainId } = useCurrentChainId()
  const dispatch = useAppDispatch();
  const navigator = useNavigate()
  const handleChainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if(Number(chainId) === Number(value)){
      setAnchorEl(null)
    }else{
      dispatch(setChain(SUPPORTED_CHAINS.find(item => Number(item.id) === Number(value))))
      navigator('/overview')
      setAnchorEl(null)
    }
  };

  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const {network = '', ...restQuery } = qs.parse(location.search.replace('?', ''))
  useEffect(() => {
    if(!network){
      return ;
    }
    const selectedChain = SUPPORTED_CHAINS.find(item => item.network === network);
    if(!isEmpty(selectedChain)){
      dispatch(setChain(SUPPORTED_CHAINS.find(item => item.network === network)));
    }
    navigator(`${location.pathname}${!isEmpty(restQuery) ? `?${qs.stringify(restQuery)}` : ''}`)
  },[network, dispatch])

  return (
    <Grid className="flex relative items-center rounded-full rounded-3xl px-1.5" sx={{borderRadius: 16}}>
      <Paper className="flex px-1 items-center cursor-pointer" sx={{borderRadius: 16, height: 35}} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Avatar src={ethSvg}/>
        <Typography className="pl-0.5" component='div'>{SUPPORTED_CHAINS.find(item => Number(item.id) === Number(chainId))?.name}</Typography>
        {Boolean(anchorEl) ? (
          <ArrowDropUp />
        ) : (
          <ArrowDropDown />
        )}
      </Paper>
      <Popover 
        id=""
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{width: 225}} className="py-1.25">
          <Typography color="text.secondary" variant="body2" component='div' gutterBottom className="mb-1 px-1.25">Select Network</Typography>
          {SUPPORTED_CHAINS.map((item) => (
            <StyledMenu key={item.id} value={item.id} sx={{ fontSize: 14 }} onClick={(e: any) => handleChainChange(e)}>
              <div style={{width: 24, height: 24}} className="mr-0.5" >
                <Badge invisible={true} variant="dot" badgeContent=' ' color='success' anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}><img src={ethIcon} alt="" width="24"/></Badge>
              </div>
              {item.name}
            </StyledMenu>
          ))}
        </div>
      </Popover>
    </Grid>
  );
}
