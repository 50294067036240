import React, {memo} from 'react';
import { Star } from '../../components/svg-icon';
import {useSnackbar} from 'notistack';
import { useAppDispatch } from 'app/hooks';
import {removeWatchToken, removeWatchPool, setWatchToken, setWatchPool} from '../../reducer/watchlist';
import { useTheme } from '@mui/material';
import { useCurrentChainId } from 'hooks/useCurrentChainId';

const StarComponent = memo(function StarComponent(props: any){
  const {watched, type="", address, dumb = false} = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const theme = useTheme()
  const { chainId } = useCurrentChainId();
  const handleWatch = () => {
    if(watched){
      if(type === 'token') {
        dispatch(removeWatchToken({chainId, data: address}))
      }else if(type ==='pool'){
        dispatch(removeWatchPool({chainId, data: address}))
      }
      !dumb && enqueueSnackbar('Remove watch success !', {variant:'success'})
    }else{
      if(type === 'token') {
        dispatch(setWatchToken({chainId, data: address}))
      }else if(type ==='pool'){
        dispatch(setWatchPool({chainId, data: address}))
      }
      !dumb && enqueueSnackbar('Set watch success !', {variant:'success'})
    }
  }
  return (
    <Star className="ml-0.5 cursor-pointer" color={watched ? theme.palette.text.primary : theme.palette.text.secondary} onClick={handleWatch}/>
  )
})

export default StarComponent